import { Box, Button } from "@mui/material";
import { useTranslate } from "react-admin";



const SurverDefaultAnswer = ({ question, handleAnswer }: { question: any, handleAnswer: any }) => {

    const translate = useTranslate();
    let answeryes: any = '';
    let answerno: any = '';

    if (question.question_type === 'boolean'){
        answeryes = true;
        answerno = false;
    } else {
        answeryes = question.answeryes;
        answerno = question.answerno;
    }

    return (
        <Box>
            {question.answeryes && (
                <Button
                    variant="contained"
                    size="small"
                    color="success"
                    onClick={() => handleAnswer('answer_' + question.id, answeryes, {field: 'positive_' + question.id, value: true})}
                    sx={{mt: 1}}
                >
                    {translate("Yes")}
                </Button>
            )}
            {question.answerno && (
                <Button
                    variant="contained"
                    size="small"
                    color="error"
                    onClick={() => handleAnswer('answer_' + question.id, answerno, {field: 'positive_' + question.id, value: false})}
                    sx={{ mx: 1, mt: 1 }}
                >
                    {translate("No")}
                </Button>
            )}
            <Button
                variant="contained"
                size="small"
                color="warning"
                onClick={() => handleAnswer('answer_' + question.id, question.na_answer ? question.na_answer : 'NA', {field: 'positive_' + question.id, value: false})}
                sx={{ mx: 1, mt: 1 }}
            >
                {translate("NA")}
            </Button>
            {question.cbsanswer && question.question_type !== 'select' && question.question_type !== 'radio' && question.question_type !== 'checkbox' && (
                <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    onClick={() => handleAnswer('answer_' + question.id, question.cbsanswer)}
                    sx={{mt: 1}}
                >
                    {translate("Default Answer")}
                </Button>
            )}
        </Box>
    )

}

export default SurverDefaultAnswer;