import { useState } from "react";
import { MenuItemLink, useSidebarState, useTranslate } from "react-admin";
import { Box } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { IconSettings, Icon } from '@salesforce/design-system-react';
import clsx from "clsx";
import { useAppMenuStyles } from "./style";
import SubMenu from "./SubMenu";


const MenuItemIcon = ({ icon }: { icon: any }) => {
    if (!icon) return <MenuIcon />;
    return (
        <IconSettings iconPath="LD/assets/icons" style={{ minWidth: '40px' }}>
            <div className={`${icon?.containerClass}`}>
                <Icon
                    category={icon?.library}
                    name={icon?.icon}
                    className={`${icon.class}`}
                    size="small"
                    style={{ width: '1.2rem', height: '1.2rem' }}
                />
            </div>
        </IconSettings>
    )
}

const CBMenuItemLink = ({ menuItem, dense }: { menuItem: any, dense: boolean }) => {
    if (menuItem.type === 'url') {
        return (
            menuItem.menuItemVisibility && (<ul className="MuiList-root MuiList-padding" tabIndex={-1}>
                <li>
                    <a tabIndex={0} role={'menuitem'} aria-disabled={false} href={menuItem.name} target="_blank" rel="noreferrer" className="MuiButtonBase-root MuiListItem-root MuiMenuItem-root RaMenuItemLink-root MuiMenuItem-gutters MuiListItem-gutters MuiListItem-button">
                        {menuItem.icon &&
                            <MenuItemIcon icon={menuItem.icon} />
                        }
                        <span style={{ marginLeft: '4px' }}>{menuItem.label}</span>
                    </a>
                </li>
            </ul>)
        )
    }

    return (
        menuItem.menuItemVisibility && (<MenuItemLink
            key={menuItem.name}
            to={{
                pathname: `/${menuItem.name}`,
            }}
            primaryText={menuItem.label}
            leftIcon={<MenuItemIcon icon={menuItem.icon} />}
            dense={dense}
        />)
    )
}

const AppMenu = ({ menuItemsList, dense=false } : { menuItemsList: any[], dense?: boolean }) => {
    const [sidebarIsOpen] = useSidebarState();
    const classes = useAppMenuStyles();
    const [anchorElIndex, setAnchorElIndex] = useState("");
    const [anchorParentElIndex, setAnchorParentElIndex] = useState("");
    const [open, setOpen] = useState(false);
    const [open2ndLevel, setOpen2ndLevel] = useState(false);
    const translate = useTranslate();



    const handleToggle = (parentMenuIndex: any, menuIndex: any) => {
        if (!parentMenuIndex) {
            setOpen(!open);
        } else {
            setOpen2ndLevel(!open2ndLevel);
        }
        setAnchorParentElIndex(parentMenuIndex);
        setAnchorElIndex(menuIndex);
    };

    return (
        <div className={clsx(classes.root)} >
            <div
                className={clsx({
                    [classes.open]: sidebarIsOpen,
                    [classes.closed]: !sidebarIsOpen,
                    [classes.menuLeft]: false,
                })}
            >

                <div className={classes.raFixedSidebar}>
                <Box className={classes.menuLeft}>
                        <Box key="homeLinkdashboard">
                            <MenuItemLink
                                key={'homeLink'}
                                to={{
                                    pathname: '/',
                                }}
                                primaryText={translate('Dashboard')}
                                leftIcon={<DashboardIcon />}
                                dense={dense}
                            />
                        </Box>

                        {
                            <>
                                {menuItemsList?.map((menu: any, menuIndex: number) => {
                                    return (
                                        <Box key={menu.menuId+menuIndex}>
                                            {menu.menuItems.length <= 0 ? (
                                                <CBMenuItemLink key={menu.menuId+menuIndex} menuItem={menu} dense={false} />
                                            ) : (
                                                menu?.menuItemVisibility &&
                                                (<SubMenu
                                                    key={menu.name + menuIndex + "submenu"}
                                                    handleToggle={() => handleToggle("", menu.menuId)}
                                                    isOpen={
                                                        open &&
                                                        (menu.menuId === anchorParentElIndex ||
                                                            menu.menuId === anchorElIndex)
                                                    }
                                                    name={menu.label}
                                                    icon={<MenuItemIcon icon={menu.icon} />}
                                                    dense={dense}
                                                >
                                                    {menu.menuItems.map((menuItem2: any, index2: number) => {
                                                        return (
                                                            <>
                                                                {menuItem2?.menuItems &&
                                                                    menuItem2.menuItems.length <= 0 ? (
                                                                    <CBMenuItemLink key={menuItem2.menuId+index2} menuItem={menuItem2} dense={false} />
                                                                ) : (
                                                                    <Box ml={2.2}>
                                                                        <SubMenu
                                                                            key={menuItem2.name + index2 + "submenu"}
                                                                            handleToggle={() =>
                                                                                handleToggle(
                                                                                    menuItem2.parentMenu,
                                                                                    menuItem2.menuId
                                                                                )
                                                                            }
                                                                            isOpen={open2ndLevel && menuItem2.menuId === anchorElIndex}
                                                                            name={menuItem2.label}
                                                                            icon={<MenuItemIcon icon={menuItem2.icon} />}
                                                                            dense={dense}
                                                                        >
                                                                            {menuItem2.menuItems.map(
                                                                                (menuItem3: any, index3: number) => {
                                                                                    return (
                                                                                        <CBMenuItemLink key={menuItem3.menuId+index3} menuItem={menuItem3} dense={false} />
                                                                                    );
                                                                                }
                                                                            )}
                                                                        </SubMenu>
                                                                    </Box>
                                                                )}
                                                            </>
                                                        );
                                                    })}
                                                </SubMenu>)
                                            )}
                                        </Box>
                                    );
                                })}
                            </>
                        }

                    </Box>
                </div>
            </div>
        </div>
    );
};

export default AppMenu;