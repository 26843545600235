import * as React from 'react';
import { forwardRef } from 'react';
import { useTranslate, UserMenu, MenuItemLink, Logout } from 'react-admin';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import { localDB_removeItem } from '../../utils/localDB';
import * as serviceWorker from '../../serviceWorker';
import { Divider } from '@mui/material';
import { getLocalDataPrefix } from '../../utils/utils';


const CustomUserMenu = forwardRef<any, any>((props, ref) => {

    const translate = useTranslate();

    const clearSomeDBData = async () => {
        const prefixLocalForageKey: string = await getLocalDataPrefix();
        await localDB_removeItem(`${prefixLocalForageKey}cbSurvey`);
        await localDB_removeItem(`${prefixLocalForageKey}cbSurveyQuestion`);
        await localDB_removeItem(`${prefixLocalForageKey}cbSurveyDone`);
        await localDB_removeItem(`${prefixLocalForageKey}cbSurveyAnswer`);
        await localDB_removeItem(`${prefixLocalForageKey}synchronization`);
        serviceWorker.unregister();
        window.location.reload();
    }
    
    return (
        <UserMenu {...props}>
            <MenuItemLink
                ref={ref}
                to="/"
                primaryText={translate('ra.page.dashboard')}
                leftIcon={<DashboardOutlinedIcon />}
                onClick={props.onClick}
            />
            <MenuItemLink
                ref={ref}
                to=""
                primaryText={translate('Clear Data')}
                leftIcon={<DashboardOutlinedIcon />}
                onClick={clearSomeDBData}
            />
            <Divider />
            <Logout sx={{ml: 0.5}}  />
        </UserMenu>
    )
});

export default CustomUserMenu;