import { Box, CircularProgress } from "@mui/material";
import { Button, useTranslate } from "react-admin";
import RefreshIcon from '@mui/icons-material/Refresh';
import PollIcon from '@mui/icons-material/Poll';
import { SurveyFormType } from "../types";


const SurveyPageActions = ({isSurveyStarted, isItRefetching, isItLoading, surveyFormType, resource, refetchData, handleSurveying } : {isSurveyStarted: boolean, isItRefetching: boolean, isItLoading: boolean, surveyFormType: SurveyFormType, resource: string, refetchData: any, handleSurveying: any }) => {

    const translate = useTranslate();

    
    return (
        <Box textAlign="left" my={1} display='flex'>
            <Box mr={1}>
                <Button
                    variant='contained'
                    color='primary'
                    label={translate('Sync')}
                    onClick={() => refetchData(resource)}
                    startIcon={isItRefetching ? <CircularProgress color={isItRefetching ? 'primary' : 'secondary'} size={24} /> : <RefreshIcon />}
                    disabled={isItRefetching || isItLoading || isSurveyStarted}
                />
            </Box>
            <Box mr={1}>
                <Button
                    variant='contained'
                    color='primary'
                    label={ isSurveyStarted ? translate('End Wizard Survey') : translate('Start Wizard Survey')}
                    onClick={() => handleSurveying(!isSurveyStarted, 'wizard')}
                    startIcon={isItRefetching ? <CircularProgress color={isItRefetching ? 'primary' : 'secondary'} size={24} /> : <PollIcon />}
                    disabled={isItRefetching || isItLoading || (isSurveyStarted && surveyFormType !== 'wizard')}
                />
            </Box>
            <Button 
                variant='contained'
                color='primary'
                label={ isSurveyStarted ? translate('End Large Survey') : translate('Start Large Survey')}
                onClick={() => handleSurveying(!isSurveyStarted, 'normal')}
                startIcon={isItRefetching ? <CircularProgress color={isItRefetching ? 'primary' : 'secondary'} size={24} /> : <PollIcon />}
                disabled={isItRefetching || isItLoading || (isSurveyStarted && surveyFormType !== 'normal')}
            />
        </Box>
    )

}

export default SurveyPageActions;