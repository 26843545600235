import { Button, Box } from "@mui/material";
import { useTranslate } from "react-admin";

export const DefaultAnswerButtons = ({ handleAnswer }: { handleAnswer: any }) => {

    const translate = useTranslate();

    return (
        <Box display="flex">
            <Button
                variant="contained"
                size="small"
                color="success"
                onClick={() => handleAnswer('Yes')}
                sx={{ ml: 1, mr: 1 }}
            >
                {translate("Yes")}
            </Button>
            <Button
                variant="contained"
                size="small"
                color="error"
                onClick={() => handleAnswer('No')}
                sx={{ mr: 1 }}
            >
                {translate("No")}
            </Button>
            <Button
                variant="contained"
                size="small"
                color="warning"
                onClick={() => handleAnswer('NA')}
                sx={{ mr: 1 }}
            >
                {translate("Not Applicable")}
            </Button>
            <Button
                variant="outlined"
                size="small"
                color="primary"
                onClick={() => handleAnswer('Default')}
                sx={{ mr: 1 }}
            >
                {translate("Default Answer")}
            </Button>
        </Box>
    )

}
