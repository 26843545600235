import { useCallback, useEffect, useMemo, useState } from "react";
import { Autocomplete, Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, TextField } from "@mui/material";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { debounce } from '@mui/material/utils';
import { Create, SaveButton, SimpleForm, useTranslate, useNotify, Notification, useDelete, useCreate } from "react-admin";
import { localDB_getItem, localDB_setItem } from "../../utils/localDB";
import { CoreBosWebService } from "../../utils/lib";
import { getLocalDataPrefix } from "../../utils/utils";



const CustomToolbar = (props: any) => {
    const { handleModal, isItLoading, isSynchronizing } = props;
    const translate = useTranslate();

    return (
        <Box display='flex' p={2} justifyContent="right">
            <Button onClick={handleModal}>{translate('ra.action.cancel')}</Button>
            <SaveButton
                label={translate('Send To Server')}
                icon={(isItLoading || isSynchronizing) ? <CircularProgress color={(isItLoading || isSynchronizing) ? 'primary' : 'secondary'} size={24} /> : <CloudUploadIcon />}
                disabled={isItLoading || isSynchronizing}
                alwaysEnable={!isSynchronizing}
                {...props}
            />
        </Box>
    );
}

const SurveyAnswerSyncModal = ({ handleSyncModal, open, resource, afterSave, defaultValues={}, surveyDoneList }: { handleSyncModal: any, open: boolean, resource: string, afterSave?: any, defaultValues?: any, surveyDoneList: any[]}) => {

    const translate = useTranslate();
    const [isItLoading, setIsItLoading] = useState(false);
    const [isSynchronizing, setIsSynchronizing] = useState(false);
    const [fields, setFields] = useState<any[]>([]);
    const [accounts, setAccounts] = useState<readonly any[]>([]);
    const [account, setAccount] = useState<any>(null);
    const [inputAccountName, setInputAccountName] = useState<string>('');
    const notify = useNotify();
    const [deleteOne] = useDelete();
    const [create] = useCreate();


    useEffect(() => {
        const prepareData = async () => {
            const prefixLocalForageKey: string = await getLocalDataPrefix();
            localDB_getItem(`${prefixLocalForageKey}describe`).then(async (result: any) => {
                setIsItLoading(true);
                let modFields = result[resource]?.fields ?? [];
                setFields(modFields);
            }).catch(() => {
                console.log(translate('translations.somethingWentWrong'));
            }).finally(() => {
                setIsItLoading(false);
            })
        }
        prepareData();
    }, [resource, translate])


    const fetch = useMemo(
        () =>
            debounce((request: { query: string, active: boolean }) => {
                const { query, active } = request;
                CoreBosWebService.doQuery(query).then((results: any) => {
                    if (active) {
                        setAccounts(results);
                    }
                });
            },
                300,
            ),
        [],
    );

    useEffect(() => {
        let active: boolean = true;

        if (inputAccountName === '') {
            setAccounts(account ? [account] : []);
            return undefined;
        }

        const q: string = `select * from Accounts where [{"fieldname":"accountname","operation":"contains","value":"${inputAccountName}","valuetype":"raw","joincondition":"AND","groupid":"racblgroup","groupjoin":"and"}] order by id DESC limit 0,25`
        fetch({ query: q, active: active });

        return () => {
            active = false;
        };
    }, [fetch, inputAccountName, account])


    const handleModal = () => {
        if (handleSyncModal) {
            handleSyncModal();
        }
    }

    const saveRecord = useCallback(
        async (values: any) => {
            setIsSynchronizing(true);
            const prefixLocalForageKey: string = await getLocalDataPrefix();
            const cbSurveyAnswerResourceName: string = `${prefixLocalForageKey}cbSurveyAnswer`;
            const cbSavedSurveyAnswers: any[] = await localDB_getItem(cbSurveyAnswerResourceName) ?? [];
            let answersToUpdate: any[] = [];
            let answersNotToUpdate: any[] = [];

            for (const surveyDone of surveyDoneList) {
                const {assigned_user_id, cbsurvey,description, surveydonedate} = surveyDone;
                const res = await CoreBosWebService.doCreate(resource, {assigned_user_id, cbsurvey,description, surveydonedate, ...values}).catch((error: any) => {
                    return error;
                });
                if (res?.id) {
                    await deleteOne(resource, {id: surveyDone.id});
                    await create(resource, { data: res })
                    const updatedAnswers = cbSavedSurveyAnswers.filter((answer: any) => answer.cbsurveydone === surveyDone.id).map((savedAnswer: any) => {
                        const {id, cbsurveyanswer_no, ...answerToSend} = savedAnswer;
                        return { ...answerToSend, ...{cbsurveydone: res.id, relatedwith: res.relatewith}};
                    });
                    answersToUpdate = answersToUpdate.concat(updatedAnswers);
                    const otherAnswers = cbSavedSurveyAnswers.filter((answer: any) => answer.cbsurveydone !== surveyDone.id).map((savedAnswer: any) => {
                        return {...savedAnswer};
                    });
                    answersNotToUpdate = answersNotToUpdate.concat(otherAnswers);
                } else {
                    notify(res, { type: 'error' });
                }
            }
            
            let newAnswersToSave: any[] = [];
            for (const cbAnswerToSend of answersToUpdate) {
                const answerRes = await CoreBosWebService.doCreate('cbSurveyAnswer', cbAnswerToSend).catch((error: any) => {
                    return error;
                });
                if(answerRes?.id){
                    newAnswersToSave.push(answerRes);
                }
            }

            newAnswersToSave = newAnswersToSave.concat(answersNotToUpdate);
            localDB_setItem(cbSurveyAnswerResourceName, newAnswersToSave).then(() => {
                if(afterSave){
                    afterSave();
                }  
            }).catch((err) => {
                notify(err, { type: 'error' });
            }).finally(() => {
                setIsSynchronizing(false);
            })
        },
        [afterSave, create, deleteOne, notify, resource, surveyDoneList],
    );


    return (
        <Box>
            <Dialog
                open={open}
                scroll='paper'
                aria-labelledby="survey-form-dialog-title"
                aria-describedby="survey-form-dialog-description"
                disableEscapeKeyDown
                fullWidth={false}
                maxWidth="xs"
            >
                <DialogTitle id="survey-form-dialog-title">{translate('Send To Server')}</DialogTitle>
                <DialogContent dividers>
                    <Create
                        resource={resource}
                        title={<></>}
                        component="div"
                    >
                        <SimpleForm onSubmit={(values: any) => saveRecord({ relatewith: account?.id, ...values })} defaultValues={defaultValues} toolbar={<CustomToolbar handleModal={handleModal} isItLoading={isItLoading} isSynchronizing={isSynchronizing} />}  >
                            {
                                fields.map((field: any, index: number) => {
                                    if (field.name === 'relatewith') {
                                        return (
                                            <Box mb={2} width="100%" key={field.name+index}>
                                                <Autocomplete
                                                    id="refInputAutocomplete"
                                                    fullWidth
                                                    size="medium"
                                                    getOptionLabel={(option: any) =>
                                                        typeof option === 'string' ? option : option.accountname
                                                    }
                                                    freeSolo
                                                    filterOptions={(x) => x}
                                                    options={accounts}
                                                    autoComplete
                                                    includeInputInList
                                                    filterSelectedOptions
                                                    value={account}
                                                    noOptionsText=""
                                                    onChange={(event: any, newValue: any) => {
                                                        setAccounts(newValue ? [newValue, ...accounts] : accounts);
                                                        setAccount(newValue);
                                                    }}
                                                    onInputChange={(event, newInputValue) => {
                                                        setInputAccountName(newInputValue);
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label={field.label} variant="outlined" fullWidth />
                                                    )}
                                                />
                                            </Box>
                                        )
                                    }
                                    return null;
                                })
                            }
                        </SimpleForm>
                    </Create>
                </DialogContent>
            </Dialog>
            <Notification />
        </Box>
    )
}

export default SurveyAnswerSyncModal;