import { SurveyFormType } from "../../types";
import SurveyForm from "./SurveyForm";
import SurveyFormWizard from "./SurveyFormWizard";

const QuestionAnswer = ({ resource, fields, linkFields, formType, otherProps }: { resource: string, fields: any[], linkFields: any[], formType: SurveyFormType, otherProps: any }) => {

  return (
    <>
    {formType === 'wizard'
      ? <SurveyFormWizard resource={resource} fields={fields} linkFields={linkFields} otherProps={otherProps} />
      : <SurveyForm resource={resource} linkFields={linkFields} otherProps={otherProps} />}
    </>
  );
};

export default QuestionAnswer;
