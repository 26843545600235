
// @ts-ignore
import spanishMessages from "@blackbox-vision/ra-language-spanish";

const ra = spanishMessages.ra;

ra.page.dashboard = 'Panel';
ra.page.empty = 'Vacía';
ra.page.invite = 'Invitar';
ra.action.export = 'Exportar';

// Actions:
ra.action.add = "Agregar";
ra.action.view = "Ver";
ra.action.viewAll = "Ver Todo";
ra.action.close = "Cerrar";
ra.action.cancel = "Cancelar";
ra.action.upload = "Subir";
ra.action.login = "Acceso";
ra.action.goBack = "Volver";


const es = {
    ra,
    translations: {
        somethingWentWrong: "Algo no funcionó",
        all: "Toda",
        dataUpdated: "Datos actualizados",
        unsupportedFileType: "Tipo de archivo no soportado",
        profile: "Perfil",
    },
    "Dashboard": "Panel",
    "Username": "Nombre de usuario",
    "Password": "Contraseña",
    "Data saved successfully": "Datos guardados correctamente",
    "Something went wrong": "Algo salió mal",
    "incorrect response: INVALID_SESSIONID: Session Identifier provided is Invalid": "Sesión inválida",
    "Record created successfully": "Registro creado con éxito",
    "Invalid username or password": "usuario o contraseña invalido",
    "ACCESS DENIED": "ACCESO DENEGADO",
    "Refetch": "recuperar",
    "Start Survey": "Empezar encuesta",
    "End Survey": "Terminar encuesta",
    "Start Wizard Survey": "Iniciar Encuesta Asistente",
    "End Wizard Survey": "Finalizar Encuesta Asistente",
    "Start Large Survey": "Comenzar Encuesta Completa",
    "End Large Survey": "Finalizar Encuesta Completa",
    "Sync": "sincronizar",
    "Sync to server": "Sincronizar con el servidor",
    "Synchronization completed": "Sincronización completada", 
    "Saved": "Guardado",
    "Send To Server": "enviar al servidor",
    "Send": "Enviar",
    "Clear Data": "Borrar datos",
    "Positive" : "Positiva",
    "Yes" : "Sí",
    "No" : "No",
    "Not Applicable": "No aplica",
    "Value" : "Valor",
    "Invalid Email": "Email inválido",
    "Invalid Url" : "URL inválida",
    "Surveys Done Per Type" : "Encuestas terminadas por tipo",
    "Local Surveys": "Encuestas locales",
    "Local Surveys Done": "Encuestas locales terminadas",
    "Days Since Last Sync": "Días desde la última sincronización",
    "User Logged In": "Usuario conectado",
    "cbSurvey": "Encuesta",
    "cbSurveyQuestion": "Pregunta",
    "cbSurveyDone": "Encuesta terminada",
    "cbSurveyAnswer": "Respuesta de la encuesta",
    'online': 'Conectado',
    'offline': 'Desconectado',
    'Default Answer': 'Respuesta predeterminada',
    'NA': 'NA',
    "Answer Now": "Responde ahora",
    "Select Answer": "Seleccionar respuesta",
    "Selected Questions": "Preguntas seleccionadas",
    "Select All": "Seleccionar todo",
    "Deselect All": "Deseleccionar todo",
    "Continue": "Continuar",
    "GoBack": "Volver",
    "Clear Storage": "Clear Storage",
    "ClearingStorageInfo": "Al hacer clic en el botón a continuación, se eliminará el almacenamiento local de esta aplicación y se perderán todos los datos guardados.",
};

export default es;
