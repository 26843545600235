
// @ts-ignore
import italianMessages from 'ra-language-italian';

const ra = italianMessages.ra;

ra.page.dashboard = 'Pannello di controllo';
ra.page.empty = 'Vuota';
ra.page.invite = 'Invitare';
ra.action.export = 'Esportare';

// Actions:
ra.action.add = "Aggiungere";
ra.action.view = "Visualizzazione";
ra.action.viewAll = "Mostra tutto";
ra.action.close = "Vicina";
ra.action.cancel = "Annulla";
ra.action.upload = "Caricamento";
ra.action.login = "Login";
ra.action.goBack = "Torna indietro";


const it = {
    ra,
    translations: {
        somethingWentWrong: "Qualcosa è andato storto",
        all: "Tutto",
        dataUpdated: "Dati aggiornati",
        unsupportedFileType: "Tipo di file non supportato",
        profile: "Profilo",
    },
    "Dashboard": "Pannello di controllo",
    "Username": "Nome utente",
    "Password": "Parola d'ordine",
    "Data saved successfully": "Dati salvati con successo",
    "Something went wrong": "Qualcosa è andato storto",
    "incorrect response: INVALID_SESSIONID: Session Identifier provided is Invalid": 'Sessione non valida',
    "Record created successfully": "Record creato con successo",
    "Invalid username or password": "Nome utente o password errati",
    "ACCESS DENIED": "ACCESSO NEGATO",
    "Refetch": "Recupero",
    "Start Survey": "Avvia sondaggio",
    "End Survey": "Fine sondaggio",
    "Start Wizard Survey": "Inizia sondaggio assistente",
    "End Wizard Survey": "Termina sondaggio assistente",
    "Start Large Survey": "Inizia grande sondaggio",
    "End Large Survey": "Termina grande sondaggio",
    "Sync": "Sincronizza",
    "Sync to server": "Sincronizza con il server",
    "Synchronization completed": "Sincronizzazione completata",
    "Saved": "Salvato",
    "Send To Server": "Invia al server",
    "Send": "Send",
    "Clear Data": "Cancella i dati",
    "Positive" : "Positiva",
    "Yes" : "SÌ",
    "No" : "No",
    "Not Applicable": "Non applicabile",
    "Value" : "Valore",
    "Invalid Email": "E-mail non valido",
    "Invalid Url" : "Url non valido",
    "Surveys Done Per Type" : "Sondaggi effettuati per tipo",
    "Local Surveys": "Sondaggi locali",
    "Local Surveys Done": "Sondaggi locali completati",
    "Days Since Last Sync": "Giorni dall'ultima sincronizzazione",
    "User Logged In": "Utente connesso",
    "cbSurvey": "Sondaggio",
    "cbSurveyQuestion": "Domanda di indagine",
    "cbSurveyDone": "Sondaggio completato",
    "cbSurveyAnswer": "Risposta al sondaggio",
    'online': 'online',
    'offline': 'offline',
    'Default Answer': 'Risposta predefinita',
    'NA': 'NA',
    "Answer Now": "Rispondi adesso",
    "Select Answer": "Seleziona Rispondi",
    "Selected Questions": "Domande selezionate",
    "Select All": "Seleziona tutto",
    "Deselect All": "Deselezionare tutto",
    "Continue": "Continua",
    "GoBack": "Torna indietro",
    "Clear Storage": "Clear Storage",
    "ClearingStorageInfo": "Facendo clic sul pulsante in basso, la memoria locale per questa applicazione verrà eliminata e tutti i dati salvati andranno persi",
};

export default it;