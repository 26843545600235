import { Box, Card, CardContent, Divider, Paper, Typography } from "@mui/material"
import SurveyFormCardHeader from "./SurveyFormCardHeader";

const QuestionAnswerContainer = ({title, resource, linkFields, question, children, selectionHandler} : {title: any, resource: string, linkFields: any, question: any, children: any, selectionHandler?: any}) => {

    return (
        <Box className="questionAnswerBox" sx={{ flexGrow: 1 }}>
            <Paper square elevation={0} sx={{display: "flex", alignItems: "center", height: 50, pl: 2, bgcolor: "background.default"}}>
                <Typography> {title} </Typography>
            </Paper>
            <Card sx={{ borderRadius: '20px', boxShadow: '10px 4px 25px -8px rgba(0, 0, 0, 0.56)', padding: '10px', margin: '15px auto', border: '1px solid #ddd' }}>
                <SurveyFormCardHeader question={question} resource={resource} linkFields={linkFields} selectionHandler={selectionHandler} />
                <Divider />
                <CardContent sx={{py: 4}}>
                    {children}
                </CardContent>
            </Card>
        </Box>
    )
}

export default QuestionAnswerContainer;