import { AutocompleteInput, DateInput, DateTimeInput, ImageField, ImageInput, NumberInput, ReferenceInput, required, SelectArrayInput, SelectInput, TextInput } from "react-admin";
import { FormattedBooleanInput } from "./helper";
import { dateParser, dateTimeParser, formatSearchObject } from "./utils";


const Field2InputElement = ({field, module, styles={}, describe=null, autogeneratedPlaceholder='', referToMod='', inputProps={}, props} : {field: any, module: string, styles?: any, describe: any, autogeneratedPlaceholder?: string, referToMod?: string, inputProps?: any, props?: any }) => {

    let userlist: any[] = [];
    const isDisabled = (inputProps?.disabled || !field?.editable) ?? false;
    const handleClick = inputProps['handleClick'] ?? null;
    const record = inputProps?.record ? inputProps.record : null;
    if (field.editable === false && field.uitype === 4) {
        return (
            <TextInput
                variant="outlined"
                key={field.name}
                label={field.label}
                source={field.name}
                readOnly
                value={autogeneratedPlaceholder}
            />
        );
    }

    const isMandatory: any = field.mandatory ? required() : null;

    switch (Number(field.uitype)) {
        case 21: // TextBox small
            return <TextInput disabled={isDisabled} variant="outlined" fullWidth={styles?.fullWidth}  key={field.name} label={field.label} source={field.name} validate={isMandatory} defaultValue={field?.default} />;
        //case 19: // TextBox big: This handled by ReactMde
        //	return <RichTextInput key={field?.name} label={field.label} source={field.name} multiline validate={isMandatory} defaultValue={field?.default}/>;
        case 5: // Date
            return <DateInput disabled={isDisabled} parse={dateParser} variant="outlined" fullWidth={styles?.fullWidth} key={field.name} label={field.label} source={field.name} validate={isMandatory} />;
        case 50: // DateTime
            return <DateTimeInput disabled={isDisabled} parse={dateTimeParser} variant="outlined" fullWidth={styles?.fullWidth} key={field.name} label={field.label} source={field.name} validate={isMandatory} />;
        case 7: // Number
        case 9: // Percentage
        case 71: // Currency
            return <NumberInput disabled={isDisabled} variant="outlined" fullWidth={styles?.fullWidth} key={field.name} label={field.label} source={field.name} validate={isMandatory} />;
        case 10: {// Module Relation
            if(!describe){
                return <></>;
            }
            let refMod = '';
            if(referToMod){
                refMod = referToMod;
            } else {
                refMod = field.type.refersTo[0] ?? '';
            }
            if(!describe[refMod]){
                return <></>;
            }
            let eidfield = describe[refMod].labelFields.split(',');
            return <ReferenceInput variant="outlined" fullWidth={styles?.fullWidth} key={field.name} label={field.label} source={field.name} reference={refMod} filterToQuery={(searchText: any) => formatSearchObject(refMod, searchText)} validate={isMandatory} defaultValue={field?.default} >
                        <AutocompleteInput disabled={isDisabled} key={'ref'+field.name} optionText={eidfield[0]} fullWidth={styles?.fullWidth} />
                    </ReferenceInput>;
        }
        case 52: // User Relation: Created and Modified by
        case 70: // Created and Modified Time
            return null;
        case 53: // User Relation: Assigned To
        case 101: {// User Relation
            userlist = describe[module]?.userlist ?? [];
            return <SelectInput disabled={isDisabled} variant="outlined" fullWidth={styles?.fullWidth} key={field.name} label={field.label} source={field.name} choices={userlist} optionText="username" optionValue="userid" defaultValue={field?.default} />;
        }
        case 56: // Checkbox
        return record ? <FormattedBooleanInput disabled={isDisabled} variant="outlined" key={field.name} label={field.label} source={field.name} record={record} />
                        : <FormattedBooleanInput disabled={isDisabled} variant="outlined" key={field.name} label={field.label} source={field.name} />;
        case 28: // image
        case 69: // Image
            return <ImageInput key={field.name} label={field.label} source={field.name} accept={{ 'image/*': ['.png', '.jpg', 'jpeg', '.gif'] }} placeholder={<p>Drop your file here to upload or click to select</p>} >
                    <ImageField key={'ref'+field.name} source={field.name}  />
                </ImageInput>;
        case 15: // SelectWithRole,
        case 16: // Select,
        case 1613: // SelectModules,
        case 1024: // SelectRoles,
            return <SelectInput disabled={isDisabled} variant="outlined" fullWidth={styles?.fullWidth} key={field.name} label={field.label} source={field.name} choices={field.type.picklistValues} optionText="label" optionValue="value" validate={isMandatory} defaultValue={field?.default} />;
        case 33: // SelectMultiple,
        case 3313: // SelectModulesMultiple,
            return <SelectArrayInput disabled={isDisabled} variant="outlined" fullWidth={styles?.fullWidth} key={field.name} label={field.label} source={field.name} choices={field.type.picklistValues} optionText="label" optionValue="value" validate={isMandatory} />;
        case 13: // Email
            return <TextInput disabled={isDisabled} onClick={(e)=>handleClick ? handleClick(e,true) : null} variant="outlined" fullWidth={styles?.fullWidth} key={field.name} label={field.label} source={field.name} type="email" validate={isMandatory} defaultValue={field?.default} />;
        case 17: // URL
            return <TextInput disabled={isDisabled} variant="outlined" fullWidth={styles?.fullWidth} key={field.name} label={field.label} source={field.name} type="url" validate={isMandatory} defaultValue={field?.default} />;
        case 1:
        case 11: // Phone
        case 14: // Time
        case 85: // Skype
        case 4:  // mod_alert_arr.AutoGenerated,
        default:
            return <TextInput disabled={isDisabled} variant="outlined" fullWidth={styles?.fullWidth} key={field.name} label={field.label} source={field.name} validate={isMandatory} defaultValue={field?.default} />;
    }
}

export default Field2InputElement;