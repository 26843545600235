import { DateField, EmailField, FunctionField, ImageField, NumberField, ReferenceField, RichTextField, SelectField, TextField, UrlField } from "react-admin";
import { FormattedBooleanField } from "./helper";
import Emailicon from '@mui/icons-material/EmailOutlined';


const Field2DisplayElement = ({field, module, describe=null, referToMod='', referenceCustomLink='', handleClick=null, handleFieldClick, props} : {field: any, module: string, describe: any, referToMod: string, referenceCustomLink: string, handleClick: any, handleFieldClick:any, props?: any}) => {

    let userlist: any[] = [];

    switch (Number(field.uitype)) {
        case 21: // TextBox small
            return <TextField key={field.name} label={field.label} source={field.name} {...props} />;
        case 19: // TextBox big
            return <RichTextField key={field.name} label={field.label} source={field.name} {...props} />;
        case 5: // Date
            return <DateField key={field.name} label={field.label} source={field.name} {...props} />;
        case 50: // DateTime
            return <DateField key={field.name} label={field.label} source={field.name} showTime {...props} />;
        case 7: // Number
            return <NumberField key={field.name} label={field.label} source={field.name} {...props} />;
        case 9: // Percentage
            return <NumberField key={field.name} label={field.label} source={field.name} options={{ style: 'percent' }} {...props} />;
        case 71: // Currency
            return <NumberField key={field.name} label={field.label} source={field.name} options={{ style: 'currency', currency: 'EUR' }} {...props} />;
        case 10: {// Module Relation
            if(!describe){
                return <></>;
            }
            let refMod = '';
            if(referToMod){
                refMod = referToMod;
            } else {
                refMod = field.type.refersTo[0] ?? '';
            }
            if(!describe[refMod]){
                return <></>;
            }
            let eidfield = describe[refMod].labelFields.split(',');
            const refLink: any = handleClick ? null : referenceCustomLink ?  referenceCustomLink : 'show';
            return (
                <ReferenceField 
                key={field.name} 
                label={field.label}  
                source={field.name}
                reference={refMod} 
                link={() => refLink}
                sortBy={refMod+'.'+eidfield[0]} 
                {...props}
                >
                    { handleClick ? 
                        <FunctionField key={'ref'+field.name} label={field.label} source={field.name} {...props} render={(aRecord: any) => {
                            return (
                                <TextField key={'ref'+field.name} source={eidfield[0]} onClick={ () => handleClick(aRecord, refMod, field)} color='primary' style={{cursor: 'pointer'}} /> 
                            )}
                        } />
                        : <TextField key={'ref'+field.name} source={eidfield[0]} /> } 
                </ReferenceField>
            );
        }
        case 101: // User Relation
        case 53: // User Relation: Assigned To
            userlist = describe[module]?.userlist ?? [];
            return (
                <SelectField
                    key={field.name}
                    label={field.label}
                    source={field.name}
                    choices={userlist}
                    optionText="username"
                    optionValue="userid"
                    {...props}
                />
            );
        case 52: // User Relation: Created and Modified by
            userlist = describe[module]?.userlist ?? [];
            return <SelectField key={field.name} label={field.label} source={field.name} choices={userlist} optionText="username" optionValue="userid" {...props} />;
        case 13: // Email
            if (handleClick) {
                return (
                    <FunctionField key={'ref'+field.name} label={field.label} source={field.name} {...props} render={(aRecord: any) => {
                        return (
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start', width: 'auto'}}>
                                <TextField onClick={ () => handleClick(aRecord, true, field.name)} source={field.name} color='primary' style={{ cursor: "pointer"}} />
                                <Emailicon onClick={ () => handleClick(aRecord, true, field.name)} style={{marginLeft: 20, fontSize: 20, cursor: 'pointer'}}/>
                            </div> 
                        )}
                    } />
                )
                }
            return <EmailField key={field.name} label={field.label} source={field.name} style={{textDecoration: 'none'}} {...props} />;
        case 17: // URL
            return <UrlField key={field.name} label={field.label} source={field.name} {...props} />;
        case 56: // Checkbox
             return (
                <FunctionField key={field.name} label={field.label} source={field.name} {...props} render={(aRecord: any) => {
                    return (
                        <FormattedBooleanField key={field.name} label={field.label} fullWidth source={field.name} record={aRecord} {...props} />
                    )}
                } />
             )
        case 28: // Image
        case 69: // Image
            return <>
                { handleClick ? 
                <FunctionField key={'ref'+field.name} label={field.label} source={field.name} {...props} render={(aRecord: any) => {
                    return (
                        <span onClick={ () => handleClick(aRecord, module, field)} style={{cursor: 'pointer'}}> <ImageField key={field.name} label={field.label} source={field.name} /> </span>
                    )}
                } />
                : <ImageField key={field.name} label={field.label} source={field.name} {...props} />} 
            </>
        case 15: // SelectWithRole,
        case 16: // Select,
        case 1613: // SelectModules,
        case 1024: // SelectRoles,
        case 33: // SelectMultiple,
        case 3313: // SelectModulesMultiple,
            return <SelectField key={field.name} label={field.label} source={field.name} choices={field.type.picklistValues} optionText="label" optionValue="value" {...props} />;
        case 1:
        case 11: // Phone
        case 14: // Time
        case 85: // Skype
        case 4:  // mod_alert_arr.AutoGenerated,
        default:
            return <TextField key={field.name} label={field.label} source={field.name} {...props} />;
    }
}

export default Field2DisplayElement;