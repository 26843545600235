import { useCallback, useEffect, useState } from "react";
import { Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, Divider } from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import { Create, SaveButton, SimpleForm, useTranslate, Notification } from "react-admin";
import { localDB_getItem, localDB_setItem } from "../../utils/localDB";
import Field2InputElement from "../../utils/Field2InputElement";
import { SynchronizationResult } from "../../types";
import { getLocalDataPrefix } from "../../utils/utils";


const CustomToolbar = (props: any) => {
    const { handleModal, isItLoading } = props;
    const translate = useTranslate();

    return (
        <>  <Divider />
            <Box sx={{width: '50%', mx: 'auto', display: 'flex', mt: 2}}>
                <Button size="small" variant="contained" color="error" onClick={() => handleModal('cancel')} sx={{mr: 1}}>{translate('ra.action.cancel')}</Button>
                <SaveButton
                    label={translate('ra.action.save')}
                    icon={isItLoading ? <CircularProgress color={isItLoading ? 'primary' : 'secondary'} size={24} /> : <SaveIcon />}
                    disabled={isItLoading }
                    alwaysEnable={!isItLoading}
                    size="small"
                    {...props}
                />
            </Box>
        </>
    );
}

const SurveyDoneCreateModal = ({ handleSurverDoneModal, open, resource, defaultValues, title='' }: { handleSurverDoneModal: any, open: boolean, resource: string, defaultValues: any, title?: any }) => {

    const translate = useTranslate();
    const [describe, setDescribe] = useState<any>({});
    const [isItLoading, setIsItLoading] = useState(false);
    const [fields, setFields] = useState<any[]>([]);



    useEffect(() => {
        const prepareData = async () => {
            const prefixLocalForageKey: string = await getLocalDataPrefix();
            localDB_getItem(`${prefixLocalForageKey}describe`).then(async (result: any) => {
                setIsItLoading(true);
                setDescribe(result);
                let modFields = result[resource]?.fields ?? [];
                setFields(modFields);
            }).catch(() => {
                console.log(translate('translations.somethingWentWrong'));
            }).finally(() => {
                setIsItLoading(false);
            })
        }
        prepareData();
    }, [resource, translate])


    const handleModal = (action: string) => {
        if (handleSurverDoneModal) {
            handleSurverDoneModal(false, null, action);
        }
    }

    const saveRecord = useCallback(
        async (values: any) => {
            setIsItLoading(true);
            const prefixLocalForageKey = await getLocalDataPrefix();
            const syncName: any = `${prefixLocalForageKey}synchronization`;
            const resourceName: any = `${prefixLocalForageKey}${resource}`;
            let savedSurveyDone: any[] = await localDB_getItem(resourceName) ?? [];
            savedSurveyDone.push(values);
            await localDB_setItem(resourceName, savedSurveyDone);
            const syncResultInit: SynchronizationResult = {
                total: 0,
                synchronized: 0,
                failed: 0,
                localSurveys: 0,
                localSurveysDone:  0,
            };
            const synchronization: SynchronizationResult = await localDB_getItem(syncName) ?? syncResultInit;
            synchronization.localSurveysDone = Number(synchronization.localSurveysDone ?? 0);
            synchronization.localSurveysDone += 1;
            await localDB_setItem(syncName, synchronization);
            handleSurverDoneModal(false, values);
            setIsItLoading(false);
        },
        [handleSurverDoneModal, resource],
    );


    return (
        <Box>
            <Dialog
                open={open}
                scroll='paper'
                aria-labelledby="survey-form-dialog-title"
                aria-describedby="survey-form-dialog-description"
                disableEscapeKeyDown
                fullWidth={true}
                maxWidth="xs"
            >
                <DialogTitle id="survey-form-dialog-title">{ title }</DialogTitle>
                <DialogContent dividers>
                    <Create
                        resource={resource}
                        title={<></>}
                        component="div"
                    >
                        <SimpleForm onSubmit={(values: any) => saveRecord(values)} defaultValues={{id: new Date().getTime(), cbsurveydone_no: `localx${new Date().getTime()}`, surveydonedate: new Date().getTime(), ...defaultValues}} toolbar={<CustomToolbar handleModal={handleModal} isItLoading={isItLoading} />} >
                            {
                                fields.map((field: any, index: number) => {
                                    const inputProps = {disabled: true}
                                    if(field.name === 'description' || field.name === 'surveydonedate'){
                                        inputProps.disabled = false;
                                    }
                                    if (field.name === 'surveydonedate' || field.name === 'description') {
                                        return <Field2InputElement referToMod='' key={field.name+index} field={field} describe={describe} module={resource} styles={{ fullWidth: true }} inputProps={inputProps} />;
                                    }
                                    return null
                                })
                            }
                        </SimpleForm>
                    </Create>
                </DialogContent>
            </Dialog>
            <Notification />
        </Box>
    )
}

export default SurveyDoneCreateModal;