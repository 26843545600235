import React from 'react';
import { useRecordContext } from 'react-admin';
import CustomFieldLink from './CustomFieldLink';


const Title = ({ resource, label='', showTitle = true, linkField, recordProp } : { resource: string, label?: any, showTitle: boolean, linkField: string, recordProp?: any }) => {

	const record = useRecordContext();

	if(!showTitle) return <></>;
	if(label) return label;
	if(!linkField) return <></>;
	return (
		<>{' '}<CustomFieldLink resource={resource} record={recordProp ?? record}  linkField={linkField} /></>
	)
   
};

export default Title;