
import { ApplicationData, SynchronizationResult } from '../types';
import { PREFIX_LOCAL_FORAGE_KEY } from '../constant';
import { localDB_getItem, localDB_setItem } from './localDB';
import { CoreBosWebService } from './lib';
import authProvider from '../admin/authProvider';


const parseDate = (v: any) => {
    const regexp = /(\d{4})-(\d{2})-(\d{2})/;

    if(!v || !regexp.exec(v) ) return;
    let date = new Date(v);

    let match = regexp.exec(date.toISOString());
    if (match === null) return;
    let year = match[1];
    let month = match[2];
    let day = match[3];
    return [year, month, day].join("-");
};

export const loadAppData = async (coreBOSmodules: string[]) => {
    const appData: ApplicationData = {
        describe: null,
        permissions: []
    };
    try{
        const descinfo: any = await CoreBosWebService.doDescribe(coreBOSmodules) ?? {};
        appData.describe = descinfo
        for (let key in descinfo) {
            let modulePermissions = {
                module: key,
                permissions: {
                    create: descinfo[key].createable,
                    delete: descinfo[key].deleteable,
                    update: descinfo[key].updateable,
                    read: descinfo[key].retrieveable,
                }
            };
            appData.permissions.push(modulePermissions);
        }

        let userListRes = await CoreBosWebService.doInvoke('getAssignedUserList', {'module': coreBOSmodules.join(',')}, 'POST');
        userListRes = JSON.parse(userListRes);
        for (const key in userListRes) {
            appData.describe[key].userlist = userListRes[key];
        }
        console.log(appData);
    } catch(error){
        console.log('error', error);
        return appData;
    }

    return appData;
}

export const getMenu = async () => {
    const result = await CoreBosWebService.doInvoke('SearchGlobalVar', {gvname: 'BusinessMapping_Menu'}, 'GET').catch(() => {
        return null;
    });
    if(result?.id){
        let menu = await CoreBosWebService.doInvoke('ProcessMap', {mapid: result.id}, 'GET');
        return menu ? JSON.parse(menu) : null;
    }
    return null;
}

export const prepareMenu = (menuMap: any) => {
    if(!menuMap) return [];
    const menu: any = {};
    const menuItems: any[] = [];
    const moduleList: any[] = [];

    for (const key in menuMap) {
        if(menuMap.hasOwnProperty(key)) {
            if(!menu.hasOwnProperty(key)) {
                if(menuMap[key]["4"] === 0){ // Menu tier (Menu heading)
                    menu[key] = {
                        menuId: menuMap[key]['0'],
                        type: 'menu',
                        name: menuMap[key]['2'],
                        label: menuMap[key]['10'],
                        parentMenu: menuMap[key]['4'],
                        menuItemSequence: menuMap[key]['5'],
                        menuItemVisibility: menuMap[key]['6']=== '1',
                        permittedIds: menuMap[key]['7'],
                        icon: menuMap[key]['9'] ?? null,
                    };
                } else {
                    if(menuMap[key]["1"] === 'module' || menuMap[key]["1"] === 'menu' || menuMap[key]["1"] === 'url'){
                        moduleList.push({
                            menuId: menuMap[key]['0'],
                            type: menuMap[key]["1"],
                            name: menuMap[key]['2'],
                            label: menuMap[key]['3'], 
                            parentMenu: menuMap[key]['4'],
                            menuItemSequence: menuMap[key]['5'],
                            menuItemVisibility: menuMap[key]['6'] === '1',
                            permittedIds: menuMap[key]['7'],
                            icon: menuMap[key]['9'] ?? null,
                        })
                    }
                }
            } 
        }
    }

    for (const key in menu) { // 1st Level
        menuItems.push(
            {
                menuId: menu[key].menuId,
                type: menu[key].type,
                name: menu[key].name,
                label: menu[key].label,
                parentMenu: menu[key].parentMenu,
                menuItemSequence: menu[key].menuItemSequence,
                menuItemVisibility: menu[key].menuItemVisibility,
                permittedIds: menu[key].permittedIds,
                icon: menu[key].icon,
                menuItems: moduleList.filter(mod => mod.parentMenu === menu[key].menuId)
            }
        );
    }

    for (const menuItem of menuItems) { // 2nd Level
        for (const key in menuItem.menuItems) {
            menuItem.menuItems[key] = {
                menuId: menuItem.menuItems[key].menuId,
                type: menuItem.menuItems[key].type,
                name: menuItem.menuItems[key].name,
                label: menuItem.menuItems[key].label,
                parentMenu: menuItem.menuItems[key].parentMenu,
                menuItemSequence: menuItem.menuItems[key].menuItemSequence,
                menuItemVisibility: menuItem.menuItems[key]['menuItemVisibility'],
                permittedIds: menuItem.menuItems[key].permittedIds,
                icon: menuItem.menuItems[key].icon,
                menuItems: moduleList.filter(mod => mod.parentMenu === menuItem.menuItems[key].menuId)
            }
        }
    }
    return menuItems;
}

export const formatSearchObject = (module: string, searchText: string) => {
	if(!searchText){
		return;
	}
	let srch: any = {};
	srch['cblistsearch_'+module] = searchText;
	return srch;
}

export const dateParser = (v: any) => parseDate(v);
export const dateTimeParser = (v:any) => {
    if(!v) return;
    const dateTime = parseDate(v);
    if(!dateTime) return;
    const regexp = /(\d{4})-(\d{2})-(\d{2})/;
    const date = new Date(v);
    let match = regexp.exec(date.toISOString());
    if (match === null) return;

    const time = date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: false });
    return `${dateTime} ${time}`;
}

export const syncData =async  (cbsurveydoneId: string, relWith: string, cbsurveyId: string, resource: string = 'cbSurveyAnswer') => {
    const relatedWith: string = relWith ?? '';
    const prefixLocalForageKey = await getLocalDataPrefix();
    const syncName: any = `${prefixLocalForageKey}synchronization`;
    const resourceName: any = `${prefixLocalForageKey}${resource}`;
    const savedData: any = await localDB_getItem(resourceName) ?? [];
    let otherData: any[] = [];
    let dataToSync: any[] = [];
    const syncResultInit: SynchronizationResult = {
        total: savedData.length,
        synchronized: 0,
        failed: 0,
        localSurveys: 0,
        localSurveysDone:  0,
    };
    const synchronization: any = await localDB_getItem(syncName) ?? syncResultInit;
    if(savedData.length > 0){
        otherData = savedData.filter((answer: any) => answer.cbsurvey !== cbsurveyId);
        dataToSync = savedData.filter((answer: any) => answer.cbsurvey === cbsurveyId).map((newAnser: any) => {
            return {elementType: 'cbSurveyAnswer', element: {cbsurveydone: cbsurveydoneId, relatedwith: relatedWith, ...newAnser}};
        });

        const result = await CoreBosWebService.doMassUpsert(dataToSync).catch((err: any) => {
            return err;
        })
        if(result?.success_creates && result?.success_creates.length === dataToSync.length){
            await localDB_setItem(resourceName, [...result.success_creates, ...otherData]);
            synchronization.synchronized = result.success_creates.length;
            synchronization.failed = 0;
            synchronization.lastSyncDate = new Date();
            synchronization.localSurveysDone += 1;

        } else {
            const failedCreates: any[] = result.failed_creates ?? [];
            await localDB_setItem(resourceName, [...otherData, ...failedCreates]);
            synchronization.synchronized = result?.success_creates?.length;
            synchronization.failed = result?.failed_creates?.length;
            synchronization.lastSyncDate = new Date();
            synchronization.localSurveysDone += 1;
        }

        await localDB_setItem(syncName, synchronization);
    }

    return synchronization;
}

export const getUserLocalDataPrefix = (userId: string, contactId: string) : string => `${userId}_${contactId}_`;
export const getLocalDataPrefix = async () : Promise<string> => {
    let prefix: string = '';
    try {
        const identity: any = await authProvider.getIdentity();
        if(identity?.id){
            prefix = getUserLocalDataPrefix(identity.id, identity.contactid);
        }
    } catch  {
        prefix = PREFIX_LOCAL_FORAGE_KEY;
    }
   
    return prefix;
}


