import englishMessages from 'ra-language-english';

const ra = englishMessages.ra;

ra.page.dashboard = 'Dashboard';
ra.page.empty = 'Empty';
ra.page.invite = 'Invite';
ra.action.export = 'Export';

// Actions:
ra.action.add = "Add";
ra.action.view = "View";
ra.action.viewAll = "View All";
ra.action.close = "Close";
ra.action.cancel = "Cancel";
ra.action.upload = "Upload";
ra.action.login = "Login";
ra.action.goBack = "Go Back";


const en = {
    ra,
    translations: {
        somethingWentWrong: "Something went wrong",
        all: "All",
        dataUpdated: "Data updated",
        unsupportedFileType: "Unsupported file type",
        profile: "Profile",
    },
    "Dashboard": "Dashboard",
    "Username": "Username",
    "Password": "Password",
    "Data saved successfully": "Data saved successfully",
    "Something went wrong": "Something went wrong",
    "incorrect response: INVALID_SESSIONID: Session Identifier provided is Invalid": 'Invalid Session',
    "Record created successfully": "Record created successfully",
    "Invalid username or password": "Invalid username or password",
    "ACCESS DENIED": "ACCESS DENIED",
    "Refetch": "Refetch",
    "Start Wizard Survey": "Start Wizard Survey",
    "End Wizard Survey": "End Wizard Survey",
    "Start Large Survey": "Start Large Survey",
    "End Large Survey": "End Large Survey",
    "Sync": "Sync",
    "Sync to server": "Sync to server",
    "Synchronization completed": "Synchronization completed", 
    "Saved": "Saved", 
    "Send To Server": "Send to server",
    "Send": "Send",
    "Clear Data": "Clear Data",
    "Positive" : "Positive",
    "Yes" : "Yes",
    "No" : "No",
    "Not Applicable": "Not Applicable",
    "Value" : "Value",
    "Invalid Email": "Invalid Email",
    "Invalid Url" : "Invalid Url",
    "Surveys Done Per Type" : "Surveys Done Per Type",
    "Local Surveys": "Local Surveys",
    "Local Surveys Done": "Local Surveys Done",
    "Days Since Last Sync": "Days Since Last Sync",
    "User Logged In": "User Logged In",
    "cbSurvey": "Survey",
    "cbSurveyQuestion": "Survey Question",
    "cbSurveyDone": "Survey Done",
    "cbSurveyAnswer": "Survey Answer",
    'online': 'online',
    'offline': 'offline',
    'Default Answer': 'Default Answer',
    'NA': 'NA',
    "Answer Now": "Answer Now",
    "Select Answer": "Select Answer",
    "Selected Questions": "Selected Questions",
    "Select All": "Select All",
    "Deselect All": "Deselect All",
    "Continue": "Continue",
    "GoBack": "Go Back",
    "Clear Storage": "Clear Storage",
    "ClearingStorageInfo": "By Clcking the button below, your local storage for this application will be deleted and all your saved data will lost",
};

export default en;