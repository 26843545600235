import React, { useState, useEffect } from 'react';
import { useTranslate, useResourceDefinition, useResourceContext, Loading, Button, useNotify, BulkDeleteButton  } from 'react-admin';
import { Box } from '@mui/material';
import { localDB_getItem, localDB_setItem } from '../utils/localDB';
import ListView from '../components/ListView';
import RefreshIcon from '@mui/icons-material/Refresh';
import CircularProgress from '@mui/material/CircularProgress';
import SurveyDoneSyncButton from '../components/survey/SurveyDoneSyncButton';
import { CoreBosWebService } from '../utils/lib';
import { getLocalDataPrefix } from '../utils/utils';



const ListBulkActionButtons = ({resource} : {resource: string}) => (
    <>
        {resource === 'cbSurveyDone' &&
            <SurveyDoneSyncButton />
        }
        <BulkDeleteButton />
    </>
);

const PageActions = ({ resource, refetchData, isItRefetching, isItLoading } : { resource: string, refetchData: any, isItRefetching: boolean, isItLoading: boolean }) => {

    const translate = useTranslate();

    return (
        <Box textAlign="left" my={1} display='flex'>
            {(resource === 'cbSurvey' || resource === 'cbSurveyQuestion') &&
                <Box mr={1}>
                    <Button
                        variant='contained'
                        color='primary'
                        label={translate('Sync')}
                        onClick={() => refetchData()}
                        startIcon={isItRefetching ? <CircularProgress color={isItRefetching ? 'primary' : 'secondary'} size={24} /> : <RefreshIcon />}
                        disabled={isItRefetching || isItLoading}
                    />
                </Box>
            }
        </Box>
    )
}

const RaList = (props: any) => {

    const { options } = useResourceDefinition(props);
    const modPermission: any = options?.permission[0]?.permissions ?? null;
    let resource:any = useResourceContext();
    if(props.resource){
        resource = props.resource;
    }
    const [describe, setDescribe] = useState<any>({});
    const [fields, setFields] = useState<any[]>([]);
    const [label, setLabel] = useState<string>(options?.label);
    const [labelField, setLabelField] = useState<string>('');
    const [linkFields, setLinkFields] = useState<any[]>([]);
    const [filterFields, setFilterFields] = useState<any[]>([]);
    const translate = useTranslate();
    const [isItLoading, setIsItLoading] = useState<boolean>(false);
    const [isItRefetching, setIsItRefetching] = useState<boolean>(false);
    const notify = useNotify();



    let { defaultFilterValues, relmod, relfieldval, relfield }: any = props;

    const refetchData = () => {
        setIsItRefetching(true);
        const query = `SELECT * FROM ${resource} LIMIT 500`;
        CoreBosWebService.doQuery(query).then(async (result: any) => {
            const prefixLocalForageKey: string = await getLocalDataPrefix();
            if(resource === 'cbSurvey'){
                const synchronization = await localDB_getItem(`${prefixLocalForageKey}synchronization`)
                if(synchronization){
                    synchronization.localSurveys = result?.length;
                    await localDB_setItem(`${prefixLocalForageKey}synchronization`, synchronization);
                } else {
                    await localDB_setItem(`${prefixLocalForageKey}synchronization`, {localSurveys: result?.length});
                }
                const questionsQuery = `SELECT * FROM cbSurveyQuestion LIMIT 500`;
                const questions = await CoreBosWebService.doQuery(questionsQuery) ?? [];
                await localDB_setItem(`${prefixLocalForageKey}cbSurveyQuestion`, questions);
            }
            await localDB_setItem(`${prefixLocalForageKey}${resource}`, result);
            window.location.reload();
        }).catch((err: any) => {
            console.log(err);
            notify(err, { type: 'error' });
        }).finally(() => {
            setIsItRefetching(false);
        })
    }

    useEffect(() => {
        const prepareData = async () => {
            const prefixLocalForageKey: string = await getLocalDataPrefix();
            localDB_getItem(`${prefixLocalForageKey}describe`).then(async (describeData: any) => {
                setIsItLoading(true);
                setDescribe(describeData);
                setLabel(describeData[resource]?.label);
                setLinkFields(describeData[resource]?.filterFields?.linkfields ?? []);
                const labelfields = describeData[resource].labelFields.split(',');
                setLabelField(labelfields[0]);
    
                let ffields = describeData[resource]?.filterFields?.fields ?? [];
                const objFilterFields = [];
                let modFields = [];
                const allFields = describeData[resource]?.fields ?? [];
    
                for (const value of ffields) {
                    const field = allFields.find((element: any) => {
                        return element.name === value;
                    });
                    if (field) {
                        modFields.push(field);
                        objFilterFields.push(field);
                    }
                }
                for (const value of allFields) {
                    if (value.name === 'questioncat' || value.name === 'questionsubcat') {
                        modFields.push(value);
                        objFilterFields.push(value);
                    }
                }
    
                setFields(modFields);
                setFilterFields(objFilterFields);
            }).catch(() => {
                setFields([]);
                setFilterFields([]);
                console.log(translate('translations.somethingWentWrong'));
            }).finally(() => {
                setIsItLoading(false);
            })
        }
        prepareData();
    }, [resource, translate])


    return (
        <>
            {!modPermission?.read
                ? <Box justifyContent="center" alignContent="center" alignItems="center"> <h2>{translate('ACCESS DENIED')}</h2> </Box>
                : <>
                    {isItLoading
                        ? <Loading loadingPrimary={""} loadingSecondary={""} />
                        : <ListView
                            title={label}
                            resource={resource}
                            fields={fields}
                            filterFields={filterFields}
                            linkFields={linkFields}
                            labelField={labelField}
                            describe={describe}
                            modPermissions={modPermission}
                            filterDefaultValues={defaultFilterValues ?? {}}
                            relmod={relmod}
                            relfieldval={relfieldval}
                            relfield={relfield}
                            otherProps={{ pageActions: <PageActions resource={resource} refetchData={refetchData} isItRefetching={isItRefetching} isItLoading={isItLoading} />, bulkActionButtons: <ListBulkActionButtons resource={resource} />, ...props }}
                        />
                    }
                </>
            }
        </>
    )


}

export default RaList;