import { makeStyles } from '@mui/styles';

export const useLoginStyles = makeStyles((theme: any) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        '& .MuiFormLabel-root': {
            opacity: '1 !important',
            color: '#BDBDBD',
        },
        '& .MuiTextField-root': {
            margin: '10px auto',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#DCDCDC',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#DCDCDC',
            },
            '&:hover fieldset': {
              borderColor: '#DCDCDC',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#DCDCDC',
            },
        },
        '& .MuiTypography-body1': {
            fontSize: '12px'
        },
    },
    loginSection: {
        [theme.breakpoints.up('md')]: {
            width: '20%',
        },
        padding: '20px',
        position: 'absolute',
        margin: 'auto',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '75%',
        backgroundColor: theme.palette.background.paper,
    },
    authTitle: {
        fontSize: '20px'
    },
    authSubmitBtn: {
        boxShadow: 'none',
        padding: '7px 22px',
    },
}));