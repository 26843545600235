import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';
var randomColor = require('randomcolor');

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }: any) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const Piechart = ({surveydone, survey}: any) => {
   const counts: any = {};
for (const item of surveydone) {
  if (!counts[item.cbsurvey]) {
    counts[item.cbsurvey] = 0;
  }
  counts[item.cbsurvey]++;

}

const surveytitle = (surveyid: any) => {
  const surveyt = survey?.find((sur: any) => sur.id === surveyid);
  return surveyt?.cbsurveyname || ''
};

const outputArray = Object.entries(counts).map(([name, value]) => ({
  name: surveytitle(name),
  value,
}))


    return (
     
        <ResponsiveContainer width={400} height={200}>
        <PieChart width={400} height={200}>
          <Pie
            data={outputArray}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={80}
            fill="#8884d8"
            dataKey="value"
            isAnimationActive={false}
          >
            {outputArray.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={randomColor()} />
            ))}
          </Pie>
          <Tooltip/>
        </PieChart>
      </ResponsiveContainer>
     
    );
  }

export default Piechart;