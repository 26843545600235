import React, { useCallback, useEffect, useState } from 'react';
import ReactMde from "react-mde";
import ReactMarkdown from "react-markdown";
import "react-mde/lib/styles/css/react-mde-all.css";
import rehypeRaw from 'rehype-raw';

// @ts-ignore
import * as Showdown from "showdown";


export const converter = new Showdown.Converter({
    tables: true,
    simplifiedAutoLink: true,
    strikethrough: true,
    tasklists: true,
});

export const MarkDownPreview = ({ record, source }: { record: any, source: string }) => {
    return (
        <ReactMde
            value={record[source]}
            selectedTab={'preview'}
            generateMarkdownPreview={() =>
                Promise.resolve(<ReactMarkdown rehypePlugins={[rehypeRaw]} children={record[source]} />)
            }
            toolbarCommands={[]}
        />
    )
};

export const MarkDownInput = ({ record, source, handleMarkdownField, saveThisImage, cureentMarkdownInputValue}: { record: any, source: string, handleMarkdownField: any, saveThisImage?: any, cureentMarkdownInputValue: any }) => {

    const [value, setValue] = useState(record?.[source]);
    const [selectedTab, setSelectedTab] = useState<"write" | "preview">("write");

    const handleValue = useCallback((newValue: any) => {
        setValue(newValue);
        handleMarkdownField(source, newValue, cureentMarkdownInputValue);
    }, [cureentMarkdownInputValue, handleMarkdownField, source])

    useEffect(() => {
       setValue(record?.[source]);
       handleValue(record?.[source])
    }, [record, source, handleValue])


    return (
        <ReactMde
            value={value}
            onChange={(value) => handleValue(value)}
            selectedTab={selectedTab}
            onTabChange={setSelectedTab}
            generateMarkdownPreview={(markdown) =>
                Promise.resolve(<ReactMarkdown rehypePlugins={[rehypeRaw]} children={markdown} />)
            }
            childProps={{
                writeButton: {
                    tabIndex: -1
                }
            }}
            paste={ saveThisImage ? {saveImage: saveThisImage} : undefined }
        />
    )
};