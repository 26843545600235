import * as React from "react";
import { useState, useEffect } from "react";
import { Admin, Resource, Loading, DataProvider, useStore, CustomRoutes  } from "react-admin";
import { authProvider } from './authProvider';
import Login from '../auth/Login';
import { APP_META_TAGS, APP_NAME, COREBOS_URL } from "../constant";
import CustomLayout from "./layout/CustomLayout";
import { localDB_getItem, localDB_removeItem } from "../utils/localDB";
import Dashboard from "./Dashboard";
import i18nProvider from "../i18n/i18nProvider";
import { Seo } from "../components/Seo";
import RaList from "./RaList";
import RaShow from "./RaShow";
import SurveyShow from "../components/survey/SurveyShow";
import { bulkDataProvider } from "./dataProvider";
import { CoreBosWebService } from "../utils/lib";
import Health from "../components/Health";
import { Route } from "react-router-dom";




const App = () => {

  CoreBosWebService.setURL(COREBOS_URL);
  const [dataProvider, setDataProvider] = useState<DataProvider  | null>(null);
  const [network, setNetwork] = useStore('app.network', navigator.onLine);


  window.addEventListener('coreBOSSessionExpiredEvent', function (e) {
    localDB_removeItem('auth').then(() => {
      window.location.reload();
    })
  }, false);
  
  window.addEventListener('coreBOSAuthorizationRequiredEvent', function (e) {
    localDB_removeItem('auth').then(() => {
      window.location.reload();
    })
  }, false);

  useEffect(() => {
    // Update network status
    const handleStatusChange = () => {
      setNetwork(navigator.onLine);
    };

    // Listen to the online status
    window.addEventListener('online', handleStatusChange);

    // Listen to the offline status
    window.addEventListener('offline', handleStatusChange);

    // Specify how to clean up after this effect for performance improvment
    return () => {
      window.removeEventListener('online', handleStatusChange);
      window.removeEventListener('offline', handleStatusChange);
    };
  }, [network, setNetwork]);

  useEffect(() => {

    const startDataProvider = async () => {
      const combineDataProviders = await bulkDataProvider();
      setDataProvider(combineDataProviders);
    }

    if (dataProvider === null) {
      startDataProvider();
    }

  }, [dataProvider]);


  const fetchResources = async (permissions: any[]) => {
    const authUser: any = await localDB_getItem('auth');
    const loggedInData: any = authUser?.logindata ?? null;
    if (loggedInData?.userId) {
      CoreBosWebService.setSession({ sessionName: loggedInData.sessionName, userId: loggedInData.id });
      const resourceList: any[] = await localDB_getItem('ra_resources') ?? [];
      const resources: any[] = resourceList?.map((item: any) => {
        return <Resource
          key={item.name}
          name={item.name}
          list={RaList}
          show={item.name === 'cbSurvey' ? SurveyShow : RaShow }
          options={{ 
            label: item.name, 
            extraFields: 'Users.ename', 
            show: true, 
            permission: permissions?.filter(permission => permission.module === item.name),
            showTitle: item.name !== 'cbSurvey'
          }}
          />;
      });
      return resources;
    } else if(window.location.hash === '#/clear-storage') {
      return [];
    } else {
      window.location.href = '#/login';
    }
  }


  if (!dataProvider){
    return <Loading />
  }

  return (
    <>
    <Seo title={APP_NAME} description='' meta={APP_META_TAGS} />
    <Admin
      i18nProvider={i18nProvider}
      authProvider={authProvider}
      dataProvider={dataProvider}
      loginPage={Login}
      layout={CustomLayout}
      dashboard={Dashboard}
    >
      <CustomRoutes noLayout >
          <Route path="/clear-storage" element={<Health />} />
      </CustomRoutes>
      {(permissions: any) => fetchResources(permissions)}
    </Admin>
    </>
  );

}

export default App;