import { Button, MobileStepper } from "@mui/material";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { useTheme } from '@mui/material/styles';


const SurveyMobileStepper = ({ maxSteps, activeStep, handleNext, handleBack, surveyAnswer=null }: { maxSteps: number, activeStep: number, handleNext: any, handleBack: any, surveyAnswer: any }) => {

    const theme = useTheme();


    return (
        <MobileStepper
            variant="dots"
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            sx={{ width: '100%', flexGrow: 1, background: "aliceblue", borderRadius: "10px", margin: "0 auto" }}
            nextButton={
                <Button
                    size="small"
                    onClick={() => handleNext(activeStep + 1)}
                    disabled={activeStep === maxSteps || !surveyAnswer}
                >
                    {!(activeStep === maxSteps - 1) ? "Next" : "Finish"}

                    {theme.direction === "rtl" ? (
                        <KeyboardArrowLeft />
                    ) : (
                        <KeyboardArrowRight />
                    )}
                </Button>
            }
            backButton={
                <Button
                    size="small"
                    onClick={handleBack}
                    disabled={activeStep === 0}
                >
                    {theme.direction === "rtl" ? (
                        <KeyboardArrowRight />
                    ) : (
                        <KeyboardArrowLeft />
                    )}
                    Back
                </Button>
            }
        />
    )

}

export default SurveyMobileStepper;