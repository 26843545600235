import { BooleanField, BooleanInput } from 'react-admin';


export const FormattedBooleanField = (props: any) => {
	if(props.record){
		const value = Number(props.record[props.source]);
		props.record[props.source] = value === 1;
	}
	return (<BooleanField {...props} />);
};

export const FormattedBooleanInput = (props: any) => {
	if(props.record){
		props.record[props.source] = Number(props.record[props.source]);
	}
	return (<BooleanInput {...props} />);
};

export const prepareSurveyQuestions = (questions: any[] = []) => {
	let preparedData: any[] = [];
	preparedData = questions?.map((question: any) => ({ ['answer_' + question.id]: '', ['positive_' + question.id]: false })) ?? [];
	if(preparedData && preparedData.length > 0){
		preparedData = preparedData?.reduce((a: any, v: any) => ({ ...a, ...v }));
	}
	return preparedData;
}