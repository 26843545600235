import { useRedirect, useTranslate } from 'react-admin';
import { Box, Chip } from '@mui/material';



const RowActions = (props: any) => {
	
    const { record, resource } = props;
	const translate = useTranslate();
	const redirect = useRedirect();



    return (
		<Box>
			{resource && resource === 'cbSurveyDone' && record && record.cbsurveydone_no?.toString()?.split('x')[0] === 'local' &&
				<Chip
					color="secondary"
					label={translate('Continue')}
					sx={{fontWeight: 'bold', fontSize: 12, cursor: 'pointer'}}
					size="small"
					onClick={() => redirect(`/cbSurvey/${record.cbsurvey}/show?cbsurveydoneId=${record.id}&action=continue`)}
				/>
			}
		</Box>
	)
};

export default RowActions;