import { Box, Grid,  } from "@mui/material";
import { Create, Form } from "react-admin";
import SurverDefaultAnswer from "../SurverDefaultAnswer";
import SurveyAnswerInput from "../../utils/SurveyAnswerInput";

const SurveyAnswerForm = ({ values, question, handleAnswer } : { values: any, question: any, handleAnswer: any }) => {

  const choices: any[] = [];
  if(question?.cbsanswer && (question?.question_type === 'select' || question?.question_type === 'radio' || question?.question_type === 'checkbox')){
    try {
      const defaultAnswerObj = JSON.parse(question.cbsanswer);
      for (const key in defaultAnswerObj) {
        choices.push({id: key, name: defaultAnswerObj[key]})
      } 
    } catch (error) {
      console.log('Error on parsing object')
    }
  }

  return (
    <Box className="surveyAnswerForm" pb={3}>
      <Create title={<></>} component="div">
        <Form record={values} id={question.id} >
          <SurveyAnswerInput field={{ type: question.question_type, name: 'answer_' + question.id, inputProps: {id: 'answer_' + question.id}, choices: choices, required: true, label: 'Answer', onChange: handleAnswer, sx: { mt: -1 } }} />
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <SurveyAnswerInput field={{ type: 'boolean', name: 'positive_' + question.id, inputProps: {id: 'positive_' + question.id}, required: false, label: 'Positive', onChange: handleAnswer }} />
            </Grid>
            <Grid item xs={10}>
              <Box sx={{ textAlign: 'right'}}>
                <SurverDefaultAnswer question={question} handleAnswer={handleAnswer} />
              </Box>
            </Grid>
          </Grid>
        </Form>
      </Create>
    </Box>
  );
};

export default SurveyAnswerForm;
