import { useState } from "react";
import { Button, useListContext, useRefresh, useUnselectAll, useTranslate } from "react-admin";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SurveyAnswerSyncModal from "./SurveyAnswerSyncModal";
import CircularProgress from "@mui/material/CircularProgress";



const SurveyDoneSyncButton = () => {

    const resource = 'cbSurveyDone';
    const translate = useTranslate();
    const { selectedIds, data } = useListContext();
    const refresh = useRefresh();
    const unselectAll = useUnselectAll(resource);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [syncModalOpen, setSyncModalOpen] = useState<boolean>(false);
    const [selectedSurveyDoneList, setSelectedSurveyDoneList] = useState<any[]>([]);


    const handleSyncModal = (open: boolean = false) => {
        if(open){
            setIsLoading(true);
            const selectedSurveyDone: any[] = []
            data?.forEach((surveyDone: any) => {
                if(selectedIds.includes(surveyDone.id)){
                    /**
                     * Make sure you dont sync aleady synchronized surveyDone record
                     */
                    const surveyDoneModuleLocalPrefix: any = surveyDone.cbsurveydone_no?.toString()?.split('x')[0] ?? null;
                    if(surveyDoneModuleLocalPrefix && surveyDoneModuleLocalPrefix === 'local'){
                        const {id, assigned_user_id, cbsurvey,description, surveydonedate} = surveyDone;
                        selectedSurveyDone.push({id, assigned_user_id, cbsurvey,description, surveydonedate});   
                    } 
                }
            });
            setSelectedSurveyDoneList(selectedSurveyDone);
        }
        setSyncModalOpen(open);
    }

    const afterSave = () => {
        unselectAll();
        refresh();
        handleSyncModal(false);
        setTimeout(() => {
            setIsLoading(false);
            window.location.reload();
        }, 2000);
    }

    return (
        <>
            <Button
                label={translate('Send To Server')}
                disabled={isLoading}
                startIcon={isLoading ? <CircularProgress color="primary" size={16} /> : <CloudUploadIcon />}
                onClick={() => handleSyncModal(true)}
            >
                <CloudUploadIcon />
            </Button>

            <SurveyAnswerSyncModal handleSyncModal={handleSyncModal} open={syncModalOpen} resource={resource} afterSave={afterSave} surveyDoneList={selectedSurveyDoneList}  />
        </>
    );
}

export default SurveyDoneSyncButton;