import React, { useEffect, useState } from 'react';
import { Grid, Paper } from '@mui/material';
import Piechart from '../components/charts/piechart';
import Tablechart from '../components/charts/tablechart';
import { makeStyles } from "@mui/styles";
import { localDB_getItem } from '../utils/localDB';
import { useTranslate } from 'react-admin';
import { getLocalDataPrefix } from '../utils/utils';

const useStyle = makeStyles(() => ({
    grid: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        height: 300,
        width: '100%',
    },
    h4: {
        fontSize: 20,
        paddingLeft: 50,
    },
    statustext: {
        fontSize: 20,
    },
    online: {
        fontSize: 20,
        color: 'green'
    },
    offline: {
        fontSize: 20,
        color: 'red'
    },
    statuscontainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    }
}));

const Dashboard = () => {
    const classes = useStyle();
    const [data, setData] = useState({});
    const [surveydone, setSurveydone] = useState([]);
    const [survey, setSurvey] = useState([]);
    const translate = useTranslate();

    useEffect(() => {
        const getData = async () => {
            const prefixLocalForageKey: string = await getLocalDataPrefix();
            const syncName: any = `${prefixLocalForageKey}synchronization`;
            localDB_getItem(syncName).then(async (syncresults: any) => {
                setData(syncresults);
            })
            const resourcecbSurvey: any = `${prefixLocalForageKey}cbSurvey`;
            const resourcecbSurveyDone: any = `${prefixLocalForageKey}cbSurveyDone`;
            localDB_getItem(resourcecbSurvey).then(async (syncresults: any) => {
                setSurvey(syncresults);
            })
            localDB_getItem(resourcecbSurveyDone).then(async (syncresults: any) => {
                setSurveydone(syncresults);
            })
        }
        getData();
    }, []);


    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={6}>
                <Paper className={classes.paper}>
                    <Tablechart data={data} />
                </Paper>
            </Grid>
            <Grid item xs={12} md={6} lg={6} className={classes.grid}>
                <Paper className={classes.paper}>
                    <h4 className={classes.h4}> {translate('Surveys Done Per Type')} </h4>
                    {surveydone?.length > 0 && <Piechart surveydone={surveydone} survey={survey} />}
                </Paper>
            </Grid>
        </Grid>
    );
}

export default Dashboard;