import * as React from 'react';
import { Loading } from 'react-admin';
import { Box, Paper, Typography } from '@mui/material';
import { useLayoutStyles } from './style';
import AppBar from './CustomAppBar';
import AppMenu from './AppMenu';


const LayoutUI = ({ menuItemsList, children, isAppLoading, ...props } : { menuItemsList: any, children: any, isAppLoading: any }) => {

    const classes = useLayoutStyles();

    return (
        <div className={classes.root}>
            <div className={classes.appFrame}>
                <AppBar {...props} menuItemsList={menuItemsList} />
                <main className={classes.contentWithSidebar}>
                    <AppMenu menuItemsList={menuItemsList} />
                    <Paper elevation={0} className={classes.content}>
                        <Box mt={2}>
                            <Typography
                                variant="h6"
                                color="inherit"
                                className={classes.title}
                                id="react-admin-title"
                                style={{flex: 'inherit'}}
                            />  
                        </Box>
                        {isAppLoading ? <Loading /> : children}
                    </Paper>
                </main>
            </div>
        </div>
    );
}

export default LayoutUI;