import React from "react";
import {Link} from 'react-router-dom';
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";

const useStyle = makeStyles(() => ({
    customFieldLink: {
       textDecoration: 'none !important',
    },
}));

const CustomFieldLink = (props: {resource: any, record: any, linkField: string  }) => {

    const classes = useStyle(); 

    return (
        <Typography variant="inherit" className={classes.customFieldLink} component={Link} to={`/${props.resource}/${props.record?.id}/show`} color="primary">
            {props.record ? props.record[`${props.linkField}`] : ''}
        </Typography>
    )
}

export default CustomFieldLink;