import { RichTextInput, RichTextInputToolbar } from "ra-input-rich-text";
import { BooleanInput, CheckboxGroupInput, NumberInput, PasswordInput, RadioButtonGroupInput, SelectInput, TextInput } from "react-admin";
import PhoneInput from "react-phone-number-input";
import { SurveyAnswer } from "../types";
import 'react-phone-number-input/style.css'


const SurveyAnswerInput = ({field} : {field: SurveyAnswer}) => {

    const {type, name, label='', choices=[], optionText='name', optionValue='id', required=true, disabled=false, inputProps={}, helperText, onChange, sx} = field;

    switch (type) {
        case 'boolean':
            return (
                <BooleanInput 
                    onChange={onChange ? (event: any) => onChange(name, event.target.checked) : undefined} 
                    key={name} 
                    source={name} 
                    label={label} 
                    fullWidth 
                    variant="outlined" 
                    isRequired={required} 
                    disabled={disabled} 
                    inputProps={inputProps} 
                    helperText={helperText} 
                    sx={sx} 
                />
            )
        case 'number':
            return (
                <NumberInput 
                    onChange={(event: any) => onChange ? onChange(name, event.target.value) : undefined} 
                    key={name} 
                    source={name} 
                    label={label} 
                    fullWidth 
                    variant="outlined" 
                    isRequired={required} 
                    disabled={disabled} 
                    inputProps={inputProps} 
                    helperText={helperText} 
                    sx={sx} 
                />
            )
        case 'radio':
            return (
                <RadioButtonGroupInput 
                    choices={choices} 
                    optionText={optionText} 
                    optionValue={optionValue} 
                    onChange={(event: any) => onChange ? onChange(name, event.target.value) : undefined} 
                    key={name} 
                    source={name} 
                    label={label}  
                    variant="outlined" 
                    isRequired={required} 
                    disabled={disabled} 
                    options={inputProps} 
                    helperText={helperText} 
                    sx={sx} 
                />
            )
        case 'checkbox':
            return (
                <CheckboxGroupInput 
                    choices={choices} 
                    optionText={optionText} 
                    optionValue={optionValue} 
                    row={false} 
                    onChange={(value: any) => onChange ? onChange(name, value) : undefined} 
                    key={name} 
                    source={name} 
                    label={label}  
                    fullWidth 
                    variant="outlined" 
                    isRequired={required} 
                    disabled={disabled} 
                    options={inputProps} 
                    helperText={helperText} 
                    sx={sx} 
                />
            )
        case 'select':
            return (
                <SelectInput 
                    choices={choices} 
                    optionText={optionText} 
                    optionValue={optionValue} 
                    onChange={(event: any) => onChange ? onChange(name, event.target.value) : undefined} 
                    key={name} 
                    source={name} 
                    label={label}  
                    fullWidth 
                    variant="outlined" 
                    isRequired={required} 
                    disabled={disabled} 
                    inputProps={inputProps} 
                    helperText={helperText} 
                    sx={sx} 
                />
                )
        case 'email':
            return (
                <TextInput 
                    type="email" 
                    onChange={(event: any) => onChange ? onChange(name, event.target.value) : undefined} 
                    key={name} 
                    source={name} 
                    label={label}  
                    fullWidth 
                    variant="outlined" 
                    isRequired={required} 
                    disabled={disabled} 
                    inputProps={inputProps} 
                    helperText={helperText} 
                    sx={sx} 
                />
            )
        case 'textarea':
            return (
                <RichTextInput
                    toolbar={<RichTextInputToolbar size="small" />} 
                    onChange={(value: any) => onChange ? onChange(name, value) : undefined} 
                    key={name} 
                    source={name} 
                    label={label} 
                    fullWidth 
                    variant="outlined" 
                    isRequired={required} 
                    disabled={disabled}  
                    helperText={helperText}
                    sx={sx}
                />
            )
        case 'password':
            return (
                <PasswordInput 
                    onChange={(event: any) => onChange ? onChange(name, event.target.value) : undefined} 
                    key={name} 
                    source={name} 
                    label={label} 
                    fullWidth 
                    variant="outlined" 
                    isRequired={required} 
                    disabled={disabled} 
                    inputProps={{...inputProps, autoComplete: 'off'}} 
                    helperText={helperText} 
                    sx={sx} 
                />
            )
        case 'phone':
            return (
                <PhoneInput 
                    international 
                    defaultCountry="ES" 
                    onChange={(value) => onChange ? onChange(name, value) : undefined } 
                    key={name} 
                    source={name} 
                    label={label}  
                    variant="outlined" 
                    disabled={disabled} 
                    sx={sx} 
                />
            )
        default:
            return (
                <TextInput 
                    onChange={(event: any) => onChange ? onChange(name, event.target.value) : undefined} 
                    key={name} 
                    source={name} 
                    label={label}  
                    fullWidth 
                    variant="outlined" 
                    isRequired={required} 
                    disabled={disabled} 
                    inputProps={inputProps} 
                    helperText={helperText} 
                    sx={sx}
                />
            )
    }
}

export default SurveyAnswerInput;