import localForage from 'localforage';
import { LocalDbItem } from '../types';

export const localDB_setItem = async (item: LocalDbItem, value: any) => {
    await localForage.setItem(item, value);
} 

export const localDB_getItem = async (item: LocalDbItem) => {
    const result:any = await localForage.getItem(item);
    return result;
}

export const localDB_removeItem = async (item: LocalDbItem) => {
    await localForage.removeItem(item);
} 

export const localDB_clearItems = async () => {
    await localForage.clear();
} 