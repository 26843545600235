import { FunctionField, Show, Tab, TabbedShowLayout, TabbedShowLayoutTabs } from "react-admin";
import Title from "./RaTitle";
import { Box, Grid, Typography } from "@mui/material";
import Field2DisplayElement from "../utils/Field2DisplayElement";
import { MarkDownPreview } from "./MarkDown";


const DetailView = ({ resource, describe, label, showTitle=true, showRelatedList=false, blocks, labelField, otherProps }: { resource: string, describe: any, label: string, showTitle?: boolean, showRelatedList?: boolean, blocks: any[], labelField: string, otherProps: any }) => {


    return (
        <Show
            resource={resource}
            title={<Title resource={resource} label={label} showTitle={showTitle} linkField={labelField} />}
        >
            {!showTitle &&
                <Box my={2} px={2}>
                    <Title resource={resource} showTitle={true} linkField={labelField} />
                </Box>
            }
            <TabbedShowLayout syncWithLocation={false} tabs={<TabbedShowLayoutTabs variant="scrollable" scrollButtons="auto" />} >
                {
                    blocks?.map((block: any, bidx: number) => {
                        return (
                            <Tab key={'fbrefblk' + bidx} label={block.name}>
                                <Box style={{ width: '100%' }} py={3}>
                                    <Grid container spacing={2} >
                                        {
                                            block.fields.map((field: any, idx: number) => {
                                                if (field.uitype === '19') {
                                                    return (
                                                        <Grid key={block.name+idx} item xs={12} md={12}>
                                                            <FunctionField label={''} render={(aRecord: any) => {
                                                                return (
                                                                    <Box className='cbRaMarkdownPreview'>
                                                                        <Typography variant="subtitle2"> {field.label} </Typography>
                                                                        <MarkDownPreview record={aRecord} source={field.name} />
                                                                    </Box>
                                                                )
                                                            }
                                                            } />
                                                        </Grid>
                                                    )
                                                }

                                                return (
                                                    <Grid key={block.name+idx} item xs={12} md={6}>
                                                        <Typography variant="subtitle2"> {field.label} </Typography>
                                                        <Field2DisplayElement key={field.name} field={field} module={resource} describe={describe} referToMod='' referenceCustomLink='' handleClick={null} props={otherProps}  handleFieldClick=''/>
                                                    </Grid>
                                                )

                                            })
                                        }
                                    </Grid>
                                </Box>
                            </Tab>
                        )
                    })
                }
            </TabbedShowLayout>
        </Show>
    )
}

export default DetailView;