
export const config =  {
  appName: 'SIGPAC Portal',
  appDescription: 'Prodat Customer Portal',
  logoUrl: '',
  metaTags: [
    {
      name: 'SIGPACPortal',
      content: 'SIGPACPortal',
    },
  ],
  server: {
    url: 'https://exta.prodatonline.net/',
  },
}
 
