import frenchMessages from 'ra-language-french';

const ra = frenchMessages.ra;

ra.page.dashboard = 'Tableau de bord';
ra.page.empty = 'Vide';
ra.page.invite = 'Inviter';
ra.action.export = 'Exporter';

// Actions:
ra.action.add = "Ajouter";
ra.action.view = "Voir";
ra.action.viewAll = "Voir tout";
ra.action.close = "Fermer";
ra.action.cancel = "Annuler";
ra.action.upload = "Télécharger";
ra.action.login = "Connexion";
ra.action.goBack = "Retourner";


const fr = {
    ra,
    translations: {
        somethingWentWrong: "Quelque chose s'est mal passé",
        all: "Toute",
        dataUpdated: "Données mises à jour",
        unsupportedFileType: "Type de fichier non pris en charge",
        profile: "Profil",
    },
    "Dashboard": "Tableau de bord",
    "Username": "Nom d'utilisateur",
    "Password": "Mot de passe",
    "Data saved successfully": "Données enregistrées avec succès",
    "Something went wrong": "Quelque chose s'est mal passé",
    "incorrect response: INVALID_SESSIONID: Session Identifier provided is Invalid": 'Session invalide',
    "Record created successfully": "Record created successfully",
    "Invalid username or password": "Enregistrement créé avec succès",
    "ACCESS DENIED": "ACCÈS REFUSÉ",
    "Refetch": "Récupérer",
    "Start Survey": "Commencer l'enquête",
    "End Survey": "Fin du sondage",
    "Start Wizard Survey": "Démarrer enquête assistant",
    "End Wizard Survey": "Terminer enquête assistant",
    "Start Large Survey": "Démarrer grande enquête",
    "End Large Survey": "Terminer grande enquête",
    "Sync": "Synchroniser",
    "Sync to server": "Synchroniser avec le serveur",
    "Synchronization completed": "Synchronisation terminée",
    "Saved": "Enregistrée", 
    "Send To Server": "Envoyer au serveur",
    "Send": "Send",
    "Clear Data": "Effacer les données",
    "Positive" : "Positive",
    "Yes" : "Oui",
    "No" : "Non",
    "Not Applicable": "N'est pas applicable",
    "Value" : "Valeur",
    "Invalid Email": "Email invalide",
    "Invalid Url" : "Url invalide",
    "Surveys Done Per Type" : "Enquêtes effectuées par type",
    "Local Surveys": "Enquêtes locales",
    "Local Surveys Done": "Enquêtes locales effectuées",
    "Days Since Last Sync": "Jours depuis la dernière synchronisation",
    "User Logged In": "Utilisateur connecté",
    "cbSurvey": "Enquête",
    "cbSurveyQuestion": "Question d'enquête",
    "cbSurveyDone": "Enquête terminée",
    "cbSurveyAnswer": "Réponse à l'enquête",
    'online': 'online',
    'offline': 'offline',
    'Default Answer': 'Réponse par défaut',
    'NA': 'NA',
    "Answer Now": "Réponds maintenant",
    "Select Answer": "Sélectionnez la réponse",
    "Selected Questions": "Questions sélectionnées",
    "Select All": "Tout sélectionner",
    "Deselect All": "Tout déselectionner",
    "Continue": "Continuer",
    "GoBack": "Retourner",
    "Clear Storage": "Clear Storage",
    "ClearingStorageInfo": "En cliquant sur le bouton ci-dessous, votre stockage local pour cette application sera supprimé et toutes vos données enregistrées seront perdues",
};

export default fr;