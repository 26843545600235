import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { Button } from '@mui/material';
import moment from 'moment';
import { useGetIdentity } from 'ra-core';
import { Link } from 'react-router-dom';
import { useTranslate } from 'react-admin';


export default function Tablechart({ data }: any) {
  const { identity } = useGetIdentity();
  const translate = useTranslate();


  return (
    <TableContainer>
      <Table aria-label="caption table">
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              {translate('Local Surveys')}
            </TableCell>
            <TableCell align="right"><Link to='/cbSurvey'><Button variant="contained">Sync</Button></Link>
            </TableCell>
            <TableCell align="right">{data?.localSurveys}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              {translate('Local Surveys Done')}
            </TableCell>
            <TableCell align="right"><Link to='/cbSurveyDone'><Button variant="contained">{translate('Send')}</Button></Link>
            </TableCell>
            <TableCell align="right">{data?.localSurveysDone}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              {translate('Days Since Last Sync')}
            </TableCell>
            <TableCell align="right"></TableCell>
            <TableCell align="right">{moment(data?.lastSyncDate).format('DD/MM/YYYY HH:mm:ss')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              {translate('User Logged In')}
            </TableCell>
            <TableCell align="right"></TableCell>
            <TableCell align="right">{identity?.fullName}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}