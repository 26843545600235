import { useEffect, useState } from "react";
import { Box, Chip, Grid, Toolbar } from "@mui/material";
import { useListContext, useTranslate } from "react-admin";
import { MarkDownPreview } from "../MarkDown";
import QuestionAnswerContainer from "../QuestionAnswerContainer";
import SurveyAnswerForm from "./SurveyAnswerForm";
import { DependencyField } from "../../types";
import { prepareSurveyQuestions } from "../../utils/helper";
import { DefaultAnswerButtons } from "./DefaultAnswers";
import DoneAllIcon from '@mui/icons-material/DoneAll';



const SurveyForm = ({ resource, linkFields, otherProps }: { resource: string, linkFields: any[], otherProps: any }) => {
  const translate = useTranslate();
  const { data } = useListContext();
  const { setQuestions, setAnsweredValues, answeredValues } = otherProps;
  const [selectedQuestions, setSelectedQuestions] = useState<any>(null);
  const [selectionCounter, setSelectionCounter] = useState<number>(0);



  useEffect(() => {
    if (data) {
      setQuestions(data)
      setAnsweredValues(prepareSurveyQuestions(data))
    }
  }, [data, setAnsweredValues, setQuestions])

  const handleAnswerSave = (selectedAnswer: string = '') => {
    if(selectedAnswer){
      const theAnswers: any = {};
      for (const key in selectedQuestions) {
        const question = selectedQuestions[key];
        if(selectedAnswer === 'NA'){
          theAnswers['answer_' + key] = question.na_answer ? question.na_answer : 'NA';
          theAnswers['positive_' + key] = false;
        } else  if(selectedAnswer === 'Yes'){
          theAnswers['answer_' + key] = question.answeryes;
          theAnswers['positive_' + key] = true;
        } else  if(selectedAnswer === 'No'){
          theAnswers['answer_' + key] = question.answerno;
          theAnswers['positive_' + key] = false;
        } else {
          theAnswers['answer_' + key] = question.cbsanswer;
          theAnswers['positive_' + key] = false;
        }
      }
      setAnsweredValues((prevState: any) => ({
        ...prevState,
        ...theAnswers,
      }));
      setSelectedQuestions(null);
      setSelectionCounter(0);
      for(const key in selectedQuestions){
        const qnCheckBox = document.getElementById(`qnCheckbox${key}`);
        qnCheckBox?.click();
      }
    }
  };

  const handleAnswer = (field: string, value: any, dependencyField: DependencyField = {}) => {
    setAnsweredValues((prevState: any) => ({
      ...prevState,
      [field]: value,
    }));
    if (dependencyField?.field) {
      setAnsweredValues((prevState: any) => ({
        ...prevState,
        [`${dependencyField.field}`]: dependencyField.value,
      }));
    }
  }

  const handleSelectedQuestion = (idKey: string, question: any) => {
    if (!question) {
      const updatedQuestions = selectedQuestions;
      if(updatedQuestions){
        delete updatedQuestions[idKey];
      }
      setSelectionCounter((prevState: number) => prevState - 1);
      if (updatedQuestions && Object.keys(updatedQuestions).length === 0) {
        setSelectedQuestions(null);
      } else {
        setSelectedQuestions(updatedQuestions);
      }
    } else {
      setSelectionCounter((prevState: number) => prevState + 1);
      setSelectedQuestions((prevState: any) => ({
        ...prevState,
        [idKey]: question,
      }));
    }
  }

  const selectAllQuestions = () => {
    if(data){
      data.forEach((question: any) => {
        const qnCheckBox = document.getElementById(`qnCheckbox${question.id}`);
        qnCheckBox?.click();
      });
    }
  }


  return (
    <Box mt={3}>
      
      <Toolbar sx={{ mb: -8, border: '1px solid #ddd' }}>
        <Chip
          icon={<DoneAllIcon />}
          color="secondary"
          label={ selectedQuestions && Object.keys(selectedQuestions).length === data?.length ? translate("Deselect All") : translate("Select All")}
          sx={{fontWeight: 'bold', fontSize: 12}}
          onClick={() => selectAllQuestions()}
        />
        {selectedQuestions &&
          <>
              <Chip
                label={`${translate("Selected Questions")}: ${selectionCounter}`}
                sx={{fontWeight: 'bold', fontSize: 12}}
              />
              <DefaultAnswerButtons handleAnswer ={handleAnswerSave} />
          </>
        }
      </Toolbar>
    
      <Grid container spacing={2}>
        {data && data.length > 0 &&
          <>
            {data?.map((question: any, index: number) => (
              <Grid item xs={12} md={6} key={question.id+index}>
                <QuestionAnswerContainer question={question} resource={resource} linkFields={linkFields} title="" selectionHandler={handleSelectedQuestion}>
                  <MarkDownPreview record={question} source="cbsquestion" />
                  <Box my={-4} >
                    <SurveyAnswerForm question={question} handleAnswer={handleAnswer} values={answeredValues} {...otherProps} />
                  </Box>
                </QuestionAnswerContainer>
              </Grid>
            ))}
          </>
        }
      </Grid>
    </Box>
  );
};

export default SurveyForm;
