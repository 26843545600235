import { makeStyles } from "@mui/styles";

const sidebarProp = {
    width: '200px',
    collapsedWidth: '55px',
}

export const useLayoutStyles = makeStyles((theme: any) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        zIndex: 1,
        minHeight: '100vh',
        backgroundColor: theme.palette.background.default,
        position: 'relative',
        minWidth: 'fit-content',
        width: '100%',
    },
    appFrame: {
        display: 'flex',
        flexDirection: 'column',
        webkitBoxFlex: 1,
        flexGrow: 1,
        overflowX: 'auto',
    },
    contentWithSidebar: {
        display: 'flex',
        webkitBoxFlex: 1,
        flexGrow: 1,
        transition: 'margin 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 2,
        marginTop: '100px',
        padding: theme.spacing(4),
        paddingTop: 0,
        zIndex: 2,
        background: 'rgb(252, 252, 254)',
    },
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
}));
export const useAppBarStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    appTitle: {
        fontWeight: 'bold',
    },
    spacer: {
        flex: 1,
    },
    list: {
        width: 250,
    },
    fullList: {
        width: '100%',
    },
    logoBox: {
        position: 'absolute',
        top: '35px',
        width: '10%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    statuscontainer: {
        padding: "5px 5px",
        borderRadius: 15,
        border: '1px solid #eeeeee',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        minWidth: 70
    }
});
export const useAppMenuStyles = makeStyles(() => ({
    root: {
      flex: '0 0 auto',
      marginTop: 0,
      marginBottom: 0,
      transition: 'margin 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
      '& .MuiCircularProgress-root': {
          width: '25px !important',
          height: '25px !important',
      },
    },
    open: {
      width: sidebarProp.width,
      position: 'relative',
      transition: 'width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
      background: 'transparent',
      zIndex: 'inherit',
    },
    closed: {
      width: sidebarProp.collapsedWidth,
      position: 'relative',
      transition: 'width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
      background: 'transparent',
      zIndex: 'inherit',
    },
    menuLeft: {
      display: 'block',
      marginTop: '100px',
    },
    raFixedSidebar: {
      position: 'fixed',
      overflowX: 'hidden',
      height: '100%',
    },
    menuBox: {
      width: sidebarProp.width,
      marginTop: '8px',
      marginBottom: '8px',
      transition: 'width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    }
}));
export const useSubMenuStyles = makeStyles((theme: any) => ({
    icon: { minWidth: theme.spacing(5) },
    sidebarIsOpen: {
        '& a': {
            transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
            paddingLeft: theme.spacing(4),
        },
    },
    sidebarIsClosed: {
        '& a': {
            transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
            paddingLeft: theme.spacing(2),
        },
    },
}));