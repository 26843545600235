

export const checkIfSurveyFinished = (answeredQuestions: any): boolean => {
    let isFinished: boolean = true;
    for (const key in answeredQuestions) {
      const type = key.split('_')[0] ?? '';
      if (type && type === 'answer' && !answeredQuestions[key]) {
        isFinished = false;
        break;
      }
    }
    return isFinished;
}

export const getQuestionById = (questionId: string, questions: any[]) => {
    const found = questions?.filter((question: any) => question.id === questionId) ?? null;
    return (found?.[0]) ? found[0] : null;
}

export const handleSurveySave = (answeredQuestions: any, questions: any[], cbsurveyId: string, assignedUserId: string, surveyDoneId = '') : any[] => {
    const preparedAnswers: any[] = [];
    let answerCounter: number = 0;
    for (const key in answeredQuestions) {
      const type = key.split('_')[0] ?? '';
      const questionId = key.split('_')[1] ?? '';
      if (type === 'answer' && answeredQuestions['answer_'+ questionId]) {
        answerCounter = answerCounter + 1;
        const positive = answeredQuestions['positive_' + questionId];
        const question: any = getQuestionById(questionId, questions);
        const questionDefaultAnswer = question?.answer ?? '';
        let cbsqanswertype = '';
        if(answeredQuestions[key] === 'Yes' || answeredQuestions[key] === true || answeredQuestions[key] === '1'){
          cbsqanswertype = 'Yes';
        } else if(answeredQuestions[key] === 'No' || answeredQuestions[key] === false || answeredQuestions[key] === '0'){
          cbsqanswertype = 'No';
        } else if(answeredQuestions[key] === 'NA'){
          cbsqanswertype = 'NA';
        } else if(answeredQuestions[key] === questionDefaultAnswer){
          cbsqanswertype = questionDefaultAnswer;
        } else {
          cbsqanswertype = 'Custom';
        }
        preparedAnswers.push({ cbsurveyq: questionId, cbsqanswertype: cbsqanswertype, cbsurveyanswer: answeredQuestions[key], positive: positive, cbsurvey: cbsurveyId, cbsurveydone: surveyDoneId, cbsurveyanswer_no: surveyDoneId +'-'+answerCounter, assigned_user_id: assignedUserId })
      }
    }

    return preparedAnswers;
}