import React, { useState, useEffect} from 'react';
import { Box } from '@mui/material';
import { useTranslate, useResourceContext, useResourceDefinition, Loading } from 'react-admin';
import { localDB_getItem } from '../utils/localDB';
import DetailView from '../components/DetailView';
import { getLocalDataPrefix } from '../utils/utils';



const RaShow = () => {

    const { options } = useResourceDefinition();
	const resource: any = useResourceContext();
	const [describe, setDescribe] = useState({});
	const [blocks, setBlocks] = useState<any[]>([]);
	const modPermission = options?.permission[0]?.permissions??{};
    const [ labelField, setLabelField ] = useState(options?.label??'');
	const [ label, setLabel ] = useState(options?.label??'');
    const [isItLoading, setIsItLoading] = useState<boolean>(false);
	const translate = useTranslate();


    
    useEffect(() => {
        const prepareData = async () => {
            const prefixLocalForageKey: string = await getLocalDataPrefix();
            localDB_getItem(`${prefixLocalForageKey}describe`).then(async (result: any) => {
                setIsItLoading(true);
                setDescribe(result);
                setLabel(result[resource]?.label);
                const labelfields = result[resource].labelFields.split(',');
                setLabelField(labelfields[0]);
                let bfields: any[] = [];
                let mfields: any[] = result[resource]?.fields;
                for (const value of mfields) {
                    if (!value.block) {
                        continue;
                    }
                    let bidx = bfields.findIndex((element: any) => element.id === value.block.blockid);
                    if (bidx===-1) {
                        bfields.push({
                            id: value.block.blockid,
                            sequence: value.block.blocksequence,
                            label: value.block.blocklabel,
                            name: value.block.blockname,
                            fields: []
                        });
                        bidx = bfields.findIndex((element) => element.id === value.block.blockid);
                    }
                    bfields[bidx].fields.push(value);
                }
                setBlocks(bfields);
            }).catch(() => {
                console.log(translate('translations.somethingWentWrong'));
            }).finally(() => {
                setIsItLoading(false);
            })
        }
        prepareData();
    }, [resource, translate])



    return (
        <>
            {!modPermission?.read 
                ? <Box justifyContent="center" alignContent="center" alignItems="center"> <h2>{translate('ACCESS DENIED')}</h2> </Box>
                : <>
                    {isItLoading
                        ? <Loading loadingPrimary={""} loadingSecondary={""} />
                        : <DetailView resource={resource} blocks={blocks} describe={describe} label={label} showTitle={options?.showTitle} labelField={labelField} otherProps={{}} />
                    }
                </>
            }
        </>
    )

}

export default RaShow;