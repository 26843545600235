import localForageDataProvider from 'ra-data-local-forage';
import mainDataProvider from 'react-admin-corebos/corebosServerProvider';
import { combineDataProviders } from 'react-admin';
import { getLocalDataPrefix } from '../utils/utils';


export const bulkDataProvider = async () => {
    const prefixLocalForageKey: string = await getLocalDataPrefix();
    const localForageProvider = await localForageDataProvider({
        prefixLocalForageKey: prefixLocalForageKey
    });

    const dataProvider = combineDataProviders((resource) => {
        switch (resource) {
            case 'cbSurvey':
            case 'cbSurveyDone':
            case 'cbSurveyQuestion':
            case 'cbSurveyAnswer':
                return localForageProvider;
            default:
              return mainDataProvider;
        }
    });
    return dataProvider;
}