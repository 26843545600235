import * as React from 'react';
import {Toolbar, Box, Typography, useMediaQuery, AppBar} from '@mui/material';
import { useAppBarStyles } from './style';
import { LoadingIndicator, SidebarToggleButton, useGetIdentity, useRedirect, useStore, useTranslate } from 'react-admin';
import { APP_NAME, COMPANY_LOGO_URL } from '../../constant';
import CustomUserMenu from './CustomUserMenu';
import Brightness1Icon from '@mui/icons-material/Brightness1';


const CustomAppBar = ({ menuItemsList, ...props } : { menuItemsList: any }) => {
    const classes = useAppBarStyles();
    const translate = useTranslate();
    const { identity } = useGetIdentity();
    const isMedium = useMediaQuery((theme: any) => theme.breakpoints.up('xs'));
    const redirect = useRedirect();
    const [network] = useStore('app.network', navigator.onLine);


    
    return (
        <AppBar color='inherit' elevation={0}>
            <Toolbar style={{padding: '8px 20px', minHeight: '64px', maxHeight: '64px'}}>
                <Box display={'flex'} style={{width: isMedium ? '16%' : '40%'}} position='absolute'>
                    <Box my={'auto'}>
                        { COMPANY_LOGO_URL 
                            ?   <Box onClick={() => redirect('/')} ><img src={COMPANY_LOGO_URL} alt={'Company Logo'} style={{width: '100%', cursor: 'pointer'}} /></Box>
                            :   <Typography variant="h6" color="inherit" className={classes.appTitle}>{ APP_NAME }</Typography> 
                        }
                    </Box>
                    <SidebarToggleButton />
                </Box>
                <span className={classes.spacer} />
                <div className={classes.statuscontainer}>
                <Brightness1Icon style={{color: network ? 'green' : 'orange', fontSize: 10}}/>
                <span>&nbsp;{translate(network ? 'online' : 'offline')}</span>
                </div>
                <LoadingIndicator />
                <Box my={'auto'}>
                    <CustomUserMenu {...props} user={identity?.user} />
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default CustomAppBar;