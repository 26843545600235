import it from "./lang/it";
import en from "./lang/en";
import fr from "./lang/fr";
import es from "./lang/es";
import polyglotI18nProvider from 'ra-i18n-polyglot';

const translations: any = { fr, it, en, es };

let lang = localStorage.getItem('locale');
lang = lang && lang.split('_')[0] ? lang.split('_')[0] : 'en';

const i18nProvider = polyglotI18nProvider(
    locale => translations[locale],
    lang,
    [
        { locale: 'es', name: 'Spanish' }, 
        { locale: 'fr', name: 'Français' }, 
        { locale: 'it', name: 'Italian' }, 
        { locale: 'en', name: 'English' }
    ],
);

export default i18nProvider;
