import { useEffect, useState } from "react";
import LayoutUI from "./LayoutUI";
import { getLocalDataPrefix, getMenu, loadAppData, prepareMenu } from "../../utils/utils";
import { ApplicationData } from "../../types";
import { localDB_getItem, localDB_setItem, localDB_removeItem } from "../../utils/localDB";
import { CoreBosWebService } from "../../utils/lib";



const Layout = (props: any) => {

    const [isAppLoading, setIsAppLoading] = useState<boolean>(false);
    const [menuItemsList, setMenuItemsList] = useState<any[]>([]);


    useEffect(() => {

        const saveDescribeData = async (menuRes: any) => {
            const prefixLocalForageKey: string = await getLocalDataPrefix();
            
            const userModules: any[] = [];
            for (const key in menuRes) {
                if (menuRes.hasOwnProperty(key) && menuRes[key]['2']) {
                    if (menuRes[key]['1'] === 'module' && menuRes[key]['2'] && menuRes[key]['2'] !== 'Reports') {
                        userModules.push(menuRes[key]['2'])
                    }
                }
            }

            await localDB_removeItem(`${prefixLocalForageKey}describe`);
            await localDB_removeItem(`${prefixLocalForageKey}permissions`);
            const appAuthData: ApplicationData | null = userModules.length > 0 ? await loadAppData(userModules) : null;
            if(appAuthData){
                const { describe, permissions } = appAuthData;
                await localDB_setItem(`${prefixLocalForageKey}describe`, describe);
                await localDB_setItem(`${prefixLocalForageKey}permissions`, permissions);
                window.location.reload();
            }
        }
        const prepareData = async () => {
            const prefixLocalForageKey: string = await getLocalDataPrefix();
            localDB_getItem(`${prefixLocalForageKey}describe`).then(async (describeData: any) => {
                setIsAppLoading(true);
                const permissionsData: any = await localDB_getItem(`${prefixLocalForageKey}permissions`) ?? [];
                const userMenu: any = await localDB_getItem(`${prefixLocalForageKey}user_menu`) ?? [];
                const raResources: any[] = await localDB_getItem('ra_resources') ?? [];
                let menuRes: any = {};
                let preparedUserMenu: any[] = [];
                if (userMenu.length <= 0) {
                    menuRes = await getMenu();
                    menuRes = menuRes ?? {};
                    preparedUserMenu = prepareMenu(menuRes);
                    await localDB_setItem(`${prefixLocalForageKey}user_menu`, preparedUserMenu);
                }
                if (raResources.length <= 0) {
                    const listTypes: any = await CoreBosWebService.doListTypes() ?? {};
                    const resourceList: any[] = [];
                    for (const key in listTypes) {
                        if (Object.hasOwn(listTypes, key)) {
                            resourceList.push(listTypes[key]);
                        }
                    }
                    await localDB_setItem('ra_resources', resourceList);
                }
                
                if (!describeData || permissionsData.length <= 0 ) {
                    await saveDescribeData(menuRes);
                } 
    
                setMenuItemsList(userMenu);
                window.coreBOS.Describe = describeData;
            }).catch((error: any) => {
                console.log(error);
            }).finally(() => {
                setIsAppLoading(false);
            })
        }
        prepareData();
    }, []);


    return (
        <LayoutUI {...props} menuItemsList={menuItemsList} isAppLoading={isAppLoading} > {props.children} </LayoutUI>
    )
};

export default Layout;