import React from 'react';
import { useListContext } from 'react-admin';
import { Card, CardContent, CardHeader, Box, Typography, Divider } from '@mui/material';
import Field2DisplayElement from "../utils/Field2DisplayElement";
import CustomFieldLink from './CustomFieldLink';



const ResponsiveList = ({describe, resource, fields, linkFields, linkField, modPermission} : {describe: any, resource: string, fields: any[], linkFields: any[], linkField: string, modPermission: any}) => {

    const {data} = useListContext();

    return (
        <Box p={3}>
            {data?.map((record:any) =>
                <Card key={record.id} style={{ padding: '10px', margin: '15px auto', border: '1px solid #ddd' }}>
                    <CardHeader 
                        title={<CustomFieldLink resource={resource} record={record} linkField={linkField} />}
                    />
                    <Divider />
                    <CardContent>
                        { fields?.map((field: any) => {
                            let refMod = '';
                            if(field.name === 'relatewith' && (resource === 'cbSurveyAnswer' || resource === 'cbSurveyDone')){
                                refMod = 'Accounts';
                            }
                            return (
                                <Box my={1} key={field.name}>
                                    <Typography variant="subtitle2"> {field.label} </Typography>
                                    { linkFields.includes(field.name) 
                                        ? <CustomFieldLink linkField={field.name} resource={resource} record={record} /> 
                                        : <Field2DisplayElement key={field.name} field={field} module={resource} describe={describe} referToMod={refMod} referenceCustomLink='' handleClick={null} props={{record}} handleFieldClick=''/>
                                    }
                                </Box>
                            )
                        })}
                    </CardContent>
                </Card>
            )}
        </Box>
    );
};

export default ResponsiveList;