import { useState, useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import { useTranslate, useResourceContext, useResourceDefinition, Loading, useNotify, Notification, useGetIdentity, useSidebarState, useRedirect } from 'react-admin';
import { localDB_getItem, localDB_setItem } from '../../utils/localDB';
import DetailView from '../../components/DetailView';
import RaList from '../../admin/RaList';
import { useParams, useSearchParams } from 'react-router-dom';
import { SURVEYING_QUESTIONS_PAGE_SIZE } from '../../constant';
import { SurveyFormType } from '../../types';
import SurveyPageActions from '../SurveyPageActions';
import { getQuestionById, handleSurveySave } from './surveyUtils';
import SurveyDoneCreateModal from './SurveyDoneCreateModal';
import { CoreBosWebService } from '../../utils/lib';
import { getLocalDataPrefix } from '../../utils/utils';




const SurveyShow = () => {

    const redirect = useRedirect();
    const { options } = useResourceDefinition();
    const resource: any = useResourceContext();
    const [describe, setDescribe] = useState({});
    const [blocks, setBlocks] = useState<any[]>([]);
    const modPermission = options?.permission[0]?.permissions ?? {};
    const [linkFields, setLinkFields] = useState<any[]>([]);
    const [label, setLabel] = useState(options?.label ?? '');
    const [isItLoading, setIsItLoading] = useState<boolean>(false);
    const [isItRefetching, setIsItRefetching] = useState<boolean>(false);
    const translate = useTranslate();
    const { id} = useParams();
    const [searchParams] = useSearchParams();
    const cbsurveydoneId = searchParams?.get('cbsurveydoneId');
    const action = searchParams?.get('action');
    const notify = useNotify();
    const { identity } = useGetIdentity();
    const [ isSurveyStarted, setIsSurveyStarted ] = useState<boolean>(false);
    const [surveyFormType, setSurveyFormType] = useState<SurveyFormType>('');
    const SURVEYING_FORM_MOD = 'cbSurveyAnswer';
    const [, setSidebarOpen] = useSidebarState();
    const [answeredValues, setAnsweredValues] = useState<any>({});
    const [questions, setQuestions] = useState<any[]>([]);
    const [doneModalOpen, setDoneModalOpen] = useState<boolean>(false);

    const handleSurverDoneModal = (open: boolean = false, createdSurveyDone: any = {}, action?: string) => {
        if(action && action === 'cancel'){
            setDoneModalOpen(false);
            setIsSurveyStarted(false);
            setAnsweredValues({});
        } else if(action && action === 'close'){
            setDoneModalOpen(false);
        } else {
            setDoneModalOpen(open);
            if(createdSurveyDone?.id){
                handleAnswerSave(createdSurveyDone);
            }
        }
    }

    const handleSurveying = async (surveying: boolean, formType: SurveyFormType) => {
        setSurveyFormType(formType);
        if(surveying){
            setSidebarOpen(false);
            setIsSurveyStarted(surveying);
        } else if(resource === 'cbSurvey' && cbsurveydoneId && action === 'continue'){
                handleAnswerSave({id: cbsurveydoneId});
        } else {
            handleSurverDoneModal(true);
        }
    }

    const handleAnswerSave = async (surveyDone: any = null) => {
        const surveyId: any = id;
        const assignedUserId: any = identity?.id;
        const answeredQuestions: any[] = [];
        for (const key in answeredValues) {
            const type = key.split('_')[0] ?? '';
            const questionId = key.split('_')[1] ?? '';
            if(type === 'answer' && answeredValues['answer_'+ questionId]){
                const question: any = getQuestionById(questionId, questions);
                if(question){
                    answeredQuestions.push(question)
                }
            }
        }

        const preparedSurverAnswers: any[] = handleSurveySave(answeredValues, answeredQuestions, surveyId, assignedUserId, surveyDone?.id);

        if(preparedSurverAnswers){
            const prefixLocalForageKey = await getLocalDataPrefix();
            const resourceName: any = `${prefixLocalForageKey}${SURVEYING_FORM_MOD}`;
            let cbSurveyAnswers: any[] = await localDB_getItem(resourceName) ?? [];
            if(Array.isArray(preparedSurverAnswers)){
                cbSurveyAnswers = cbSurveyAnswers.concat(preparedSurverAnswers);
            } else {
                cbSurveyAnswers.push(preparedSurverAnswers);
            }
            await localDB_setItem(resourceName, cbSurveyAnswers);
            notify(translate('Saved'), { type: 'success' });
            setIsSurveyStarted(false);
            redirect(`/cbSurveyDone`);
            window.location.reload();
        }  
    }

    const refetchData = (mod: string) => {
        setIsItRefetching(true);
        const query = `SELECT * FROM ${mod} LIMIT 500`;
        CoreBosWebService.doQuery(query).then(async (result: any) => {
            const prefixLocalForageKey = await getLocalDataPrefix();
            const resourceName: any = `${prefixLocalForageKey}${mod}`;
            await localDB_setItem(resourceName, result);
            window.location.reload();
        }).catch((err: any) => {
            notify(err, { type: 'error' });
        }).finally(() => {
            setIsItRefetching(false);
        })
    }

    useEffect(() => {
        if(resource === 'cbSurvey' && cbsurveydoneId && action === 'continue'){
            setSidebarOpen(false);
            setSurveyFormType('normal');
            setIsSurveyStarted(true);
        }
    }, [action, cbsurveydoneId, resource, setSidebarOpen])
    

    useEffect(() => {
        const prepareData = async () => {
            const prefixLocalForageKey: string = await getLocalDataPrefix();
            localDB_getItem(`${prefixLocalForageKey}describe`).then(async (result: any) => {
                setIsItLoading(true);
                setDescribe(result);
                setLabel(result[resource]?.label);
                setLinkFields(result[resource]?.filterFields?.linkfields ?? []);
                let bfields: any[] = [];
                let mfields: any[] = result[resource]?.fields;
                for (const value of mfields) {
                    if (!value.block) {
                        continue;
                    }
                    let bidx = bfields.findIndex((element: any) => element.id === value.block.blockid);
                    if (bidx === -1) {
                        bfields.push({
                            id: value.block.blockid,
                            sequence: value.block.blocksequence,
                            label: value.block.blocklabel,
                            name: value.block.blockname,
                            fields: []
                        });
                        bidx = bfields.findIndex((element) => element.id === value.block.blockid);
                    }
                    bfields[bidx].fields.push(value);
                }
    
                setBlocks(bfields);
            }).catch(() => {
                console.log(translate('translations.somethingWentWrong'));
            }).finally(() => {
                setIsItLoading(false);
            })
        }
        prepareData();
    }, [resource, translate])

    return (
        <>
            {!modPermission?.read
                ? <Box justifyContent="center" alignContent="center" alignItems="center"> <h2>{translate('ACCESS DENIED')}</h2> </Box>
                : <>
                    {isItLoading
                        ? <Loading loadingPrimary={""} loadingSecondary={""} />
                        : <>                        
                            <SurveyPageActions resource='cbSurveyQuestion' isSurveyStarted={isSurveyStarted} isItRefetching={isItRefetching} isItLoading={isItLoading} refetchData={refetchData} handleSurveying={handleSurveying} surveyFormType={surveyFormType}  />
                            {isSurveyStarted
                                ? <RaList resource='cbSurveyQuestion' defaultFilterValues={{ cbsurvey: id }} relmod='cbSurveyQuestion' relfieldval={id} relfield='cbsurvey' listViewing='surveying' surveyFormType={surveyFormType} assigned_user_id={identity?.id} cbsurvey={id} isSurveyStarted={isSurveyStarted} setAnsweredValues={setAnsweredValues} answeredValues={answeredValues} setQuestions={setQuestions} perPage={SURVEYING_QUESTIONS_PAGE_SIZE}  />
                                : <Grid container spacing={3} >
                                    <Grid item xs={12} md={8}>
                                        <RaList resource='cbSurveyQuestion' defaultFilterValues={{ cbsurvey: id }} relmod='cbSurveyQuestion' relfieldval={id} relfield='cbsurvey' listViewing='surveying' surveyFormType={surveyFormType} assigned_user_id={identity?.id} cbsurvey={id} isSurveyStarted={isSurveyStarted} setAnsweredValues={setAnsweredValues} answeredValues={answeredValues} setQuestions={setQuestions} />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <DetailView resource={resource} blocks={blocks} describe={describe} label={label} showTitle={false} labelField={linkFields[0] ?? ''} otherProps={{}} />
                                    </Grid>
                                </Grid>
                            }
                        </>
                    }
                </>
            }
            <SurveyDoneCreateModal handleSurverDoneModal={handleSurverDoneModal} open={doneModalOpen} resource='cbSurveyDone' defaultValues={{ cbsurvey: id, assigned_user_id: identity?.id }} title={`${translate('ra.action.save') +' '+ translate('cbSurveyDone')}`}  />
            <Notification/>
        </>
    )

}

export default SurveyShow;