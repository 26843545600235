import { Box, CardHeader, Checkbox, Chip, Tooltip } from "@mui/material";
import CustomFieldLink from "./CustomFieldLink";
import InfoIcon from '@mui/icons-material/Info';
import { useState } from "react";
import {Link} from 'react-router-dom';



const SurveyFormCardHeader = ({resource, question, linkFields=[], selectionHandler} : {resource: string, question: any, linkFields: any[], selectionHandler?: any}) => {

    const [checked, setChecked] = useState(false);

    
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
        selectionHandler(question.id, event.target.checked ? question : null);
    };
    
    return (
        <CardHeader
            title={
                <Box sx={{ display: 'flex', justifyContent: 'space-between', }}>
                    <Box>
                        <CustomFieldLink
                            resource={resource}
                            record={question}
                            linkField={linkFields[0] ?? ""}
                        />
                        <Checkbox
                            checked={checked}
                            onChange={handleChange}
                            color="primary"
                            id={`qnCheckbox${question?.id}`}
                            inputProps={{ 'aria-label': 'SelectionButton' }}
                        />
                    </Box>
                    <Box display="flex" mt={1}>
                        {question?.questioncat && (
                            <Chip
                                label={question?.questioncat}
                                variant="outlined"
                                size="small"
                                sx={{ marginLeft: "10px", background: "#0067d4cf", color: "white", cursor: 'pointer' }}
                                component={Link} to={`?displayedFilters=questioncat=true&filter=${JSON.stringify({ questioncat: question.questioncat })}`}
                            />
                        )}
                        {question?.questionsubcat && (
                            <Chip
                                label={question?.questionsubcat}
                                variant="outlined"
                                size="small"
                                sx={{ marginLeft: "10px", background: "#0067d4cf", color: "white", cursor: 'pointer' }}
                                component={Link} to={`?displayedFilters=questionsubcat=true&filter=${JSON.stringify({ questionsubcat: question.questionsubcat })}`}
                            />
                        )}
                        {question?.questioncheck && (
                            <Box sx={{marginTop: "1px"}}>
                                <Tooltip
                                    title={question?.questioncheck}
                                    placement="bottom"
                                    arrow
                                >
                                    <InfoIcon style={{ marginLeft: "10px", color: "#969492" }} />
                                </Tooltip>
                            </Box>
                        )}
                    </Box>
                </Box>
            }
        />
    )
}

export default SurveyFormCardHeader;