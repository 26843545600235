import * as React from 'react';
import { ReactElement, ReactNode } from 'react';
import {
    List,
    MenuItem,
    ListItemIcon,
    Typography,
    Collapse,
    Tooltip,
    Box,
} from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import { useSidebarState } from 'react-admin';
import { useSubMenuStyles } from './style';




interface Props {
    dense: boolean;
    handleToggle: () => void;
    icon: ReactElement;
    isOpen: boolean;
    name: string;
    children: ReactNode;
}

const SubMenu = (props: Props) => {
    
    const { handleToggle, isOpen, name, icon, children, dense } = props;
    const classes = useSubMenuStyles();
    const [sidebarIsOpen] = useSidebarState();


    const header = (
        <MenuItem dense={dense} onClick={handleToggle} title={name}>
            <ListItemIcon className={classes.icon}>
                {isOpen ? <ExpandLess /> : icon }
            </ListItemIcon>
            <Typography variant="inherit" color="textSecondary">
                {name}
            </Typography>
        </MenuItem>
    );

    return (
        <Box>
            {sidebarIsOpen || isOpen ? (
                header
            ) : (
                <Tooltip title={name} placement="right">
                    {header}
                </Tooltip>
            )}
            <Collapse 
                in={isOpen} 
                timeout="auto" 
                unmountOnExit
            >
                    <List
                        dense={dense}
                        component="div"
                        disablePadding
                        className={
                            sidebarIsOpen
                                ? classes.sidebarIsOpen
                                : classes.sidebarIsClosed
                        }
                    >
                        {children}
                    </List>
            </Collapse>
        </Box>
    );
};

export default SubMenu;