import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Card from '@mui/material//Card';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import {
    useLogin,
    useNotify,
    useRedirect,
    Notification,
    useTranslate,
} from 'react-admin';
import { COMPANY_LOGO_URL } from '../constant';
import { useLoginStyles } from './loginStyle';



const Login = (props: any) => {

    const classes = useLoginStyles();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const translate = useTranslate();

    const redirect = useRedirect();
    const login = useLogin();
    const notify = useNotify();

    const onSubmit = (e: any) => {
        e.preventDefault();
        setIsLoading(true);
        login({ username, password }).then((res) => {
            redirect('/');
        }).catch(() => {
            notify('Invalid username or password', { type: 'error' });
            setIsLoading(false);
        });
    };


    return (
        <Box className={classes.root}>
            <Card className={classes.loginSection} elevation={2}>
                {COMPANY_LOGO_URL &&
                    <Box style={{ width: '75%', margin: 'auto' }}>
                        <img src={COMPANY_LOGO_URL} alt={'Company Logo'} style={{ width: '100%' }} />
                    </Box>
                }
                <Typography component="div">
                    <Box className={classes.authTitle} textAlign="center" my={2} >
                        {translate('ra.action.login')}
                    </Box>
                </Typography>
                <form onSubmit={onSubmit}>
                    <Box>
                        <TextField
                            label={translate('Username')}
                            name={'username'}
                            fullWidth
                            id="usernameInput"
                            variant="outlined"
                            size="small"
                            required
                            onChange={e => setUsername(e.target.value)}
                        />
                    </Box>
                    <Box>
                        <TextField
                            label={translate('Password')}
                            name={'password'}
                            type={'password'}
                            fullWidth
                            id="passwordInput"
                            variant="outlined"
                            size="small"
                            required
                            onChange={e => setPassword(e.target.value)}
                        />
                    </Box>
                    <Box my={2}>
                        <Button
                            fullWidth
                            type={'submit'}
                            disabled={isLoading || !username || !password}
                            startIcon={isLoading ? <CircularProgress color={isLoading ? 'primary' : 'secondary'} size={24} /> : <></>}
                            variant="contained"
                            color="primary"
                            size="large"
                            className={classes.authSubmitBtn}
                        >
                            {translate('ra.action.login')}
                        </Button>
                    </Box>
                </form>
            </Card>
            <Notification />
        </Box>
    );
};

export default Login;