import { Box, useMediaQuery } from "@mui/material";
import { Datagrid, FunctionField, List } from "react-admin";
import Field2DisplayElement from "../utils/Field2DisplayElement";
import { CBListFilter } from './CBListFilter';
import CustomFieldLink from "./CustomFieldLink";
import QuestionAnswer from "./survey/QuestionAnswer";
import ResponsiveList from "./ResponsiveList";
import { SurveyFormType } from "../types";
import RowActions from "./RowActions";



const ResponsiveListView = ({isSmallDevice, resource, fields, linkFields, filterFields, linkField, modPermissions, describe, otherProps} : {isSmallDevice: boolean, resource: string, fields: any[], linkFields: any[], filterFields: any[], linkField: string, modPermissions: any, describe: any, otherProps: any}) => {
    
    const {bulkActionButtons} = otherProps;

    if(isSmallDevice){
        return (
            <ResponsiveList resource={resource} fields={filterFields} linkFields={linkFields} linkField={linkField} describe={describe} modPermission={modPermissions} />
        )
    }

    return (
        <Datagrid rowClick={false} bulkActionButtons={bulkActionButtons}>
            {fields?.map((field: any, index: number) => {
                let refMod = '';
                if ((field.name === 'relatewith' || field.name === 'relatedwith' || field.name === 'assigned_user_id') && (resource === 'cbSurveyAnswer' || resource === 'cbSurveyDone')) {
                    return null;
                }
                return (
                    <FunctionField
                        source={field.name}
                        label={field.label}
                        key={field.name+index}
                        render={(record: any) => {
                            return linkFields.includes(field.name)
                                ? <CustomFieldLink {...otherProps} linkField={field.name} record={record} resource={resource} />
                                : <Field2DisplayElement key={field.name} field={field} module={resource} describe={describe} referToMod={refMod} referenceCustomLink='' handleClick={null} props={{ record }} handleFieldClick='' />;
                        }}
                    />
                );
            })}
            <FunctionField
                render={(record: any) => {
                    return <RowActions {...otherProps} modPermission={modPermissions} resource={resource} record={record} />
                }}
            />
        </Datagrid>
    )
}

const ListView = ({ resource, fields, filterFields, describe, modPermissions, linkFields = [], labelField = '', title = '', filterDefaultValues = {}, relmod = '', relfield = '', relfieldval = '', otherProps }: { resource: string, fields: any[], filterFields: any[], linkFields: any[], labelField: string, describe: any, modPermissions: any, title: any, filterDefaultValues: any, relmod: string, relfield: string, relfieldval: string, otherProps: any }) => {


    const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));
    const { pageActions = null, listViewing, isSurveyStarted, surveyFormType, perPage=10 }: { pageActions: any, listViewing: string, listViewingLarge: string, isSurveyStarted: any, surveyFormType: SurveyFormType, perPage: number } = otherProps;

    
    return (
        <Box>
            {listViewing !== 'surveying' && pageActions}
            <List
                {...otherProps}
                resource={resource}
                title={<>{title}</>}
                empty={<></>}
                perPage={perPage}
                filterDefaultValues={filterDefaultValues}
                filters={<CBListFilter fields={filterFields} describe={describe} disabledfield={relfield} disabledfieldStyle={{ display: 'none' }} resource={resource} />}
                component="div"
                hasList={modPermissions?.read}
                hasCreate={false}
                hasEdit={modPermissions?.update}
                hasShow={modPermissions?.read}
                storeKey={false}
            >

                {isSurveyStarted && listViewing === 'surveying'
                    ? <QuestionAnswer formType={surveyFormType} fields={fields} resource={resource} linkFields={linkFields} otherProps={otherProps} />
                    : <ResponsiveListView isSmallDevice={isSmall} describe={describe} resource={resource} fields={fields} linkFields={linkFields} filterFields={filterFields} linkField={labelField} modPermissions={modPermissions} otherProps={otherProps} />
                }
            </List>
        </Box>
    )

}

export default ListView;