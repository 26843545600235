import { useEffect, useState } from "react";
import { Box, useMediaQuery } from "@mui/material";
import { useListContext } from "react-admin";
import { MarkDownPreview } from "../MarkDown";
import SurveyAnswerForm from "./SurveyAnswerForm";
import QuestionAnswerContainer from "../QuestionAnswerContainer";
import SurveyMobileStepper from "../SurveyMobileStepper";
import { prepareSurveyQuestions } from "../../utils/helper";
import { DependencyField } from "../../types";

const SurveyFormWizard = ({ resource, fields, linkFields, otherProps }: { resource: string, fields: any[], linkFields: any[], otherProps: any }) => {

  const { data } = useListContext();
  const { setQuestions, setAnsweredValues, answeredValues } = otherProps;
  const [activeStep, setActiveStep] = useState<number>(0);
  const [prevSteps, setPrevSteps] = useState<number[]>([]);
  const isMedium = useMediaQuery((theme: any) => theme.breakpoints.up('sm'));

  const maxSteps = data ? data.length : 0;

  useEffect(() => {
    if (data) {
      setQuestions(data)
      setAnsweredValues(prepareSurveyQuestions(data))
    }
  }, [data, setAnsweredValues, setQuestions])

  const handleAnswer = (field: string, value: any, dependencyField: DependencyField = {}) => {
    setAnsweredValues((prevState: any) => ({
      ...prevState,
      [field]: value,
    }));
    if (dependencyField?.field) {
      setAnsweredValues((prevState: any) => ({
        ...prevState,
        [`${dependencyField.field}`]: dependencyField.value,
      }));
    }
  }

  const handleNext = (expectedNextStep: number) => {
    const expectedNextQuestion = data?.[expectedNextStep] ? data[expectedNextStep] : null;
    if (!expectedNextQuestion) {
      setPrevSteps((prevSteps: any) => [...prevSteps, activeStep]);
    } else {
      setActiveStep(expectedNextStep);
    }
  };

  const handleBack = () => {
    const prevStep = prevSteps.pop();
    if (prevStep !== undefined) {
      setActiveStep(prevStep);
      setPrevSteps((prevSteps: any) => prevSteps.slice(0, -1));
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  return (
    <Box sx={{ width: isMedium ? '50%' : '100%', mx: 'auto' }}>
      {data && data.length > 0 &&
        <QuestionAnswerContainer question={data?.[activeStep]} resource={resource} linkFields={linkFields} title={fields?.find((field) => field.name === "cbsquestion")?.label ?? ""}>
          <MarkDownPreview record={data?.[activeStep]} source="cbsquestion" />
          <Box my={-4} >
            <SurveyAnswerForm question={data?.[activeStep]} handleAnswer={handleAnswer} values={answeredValues} {...otherProps} />
          </Box>
          <Box sx={{ maxWidth: 400, mx: 'auto', mt: 3 }}>
            <SurveyMobileStepper maxSteps={maxSteps} activeStep={activeStep} surveyAnswer={answeredValues['answer_' + data?.[activeStep]?.id]} handleNext={handleNext} handleBack={handleBack} />
          </Box>
        </QuestionAnswerContainer>
      }
    </Box>
  );
};

export default SurveyFormWizard;

