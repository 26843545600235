import { CoreBosWebService } from '../utils/lib';
import { localDB_getItem, localDB_removeItem, localDB_setItem } from '../utils/localDB';
import { getLocalDataPrefix } from '../utils/utils';



const handleAuthentication = async (logindata: any) => {
    if (logindata !== false && CoreBosWebService.hasError(logindata) === false) {
        let coreboslogindata = logindata['result'];
        const userId = coreboslogindata?.userId || coreboslogindata?.user?.id;
        coreboslogindata = {...coreboslogindata, userId: userId };
        CoreBosWebService.setSession({sessionName: coreboslogindata?.sessionName, userId: userId});
        const res = await CoreBosWebService.doInvoke('getPortalUserInfo');
        const contactdata = await CoreBosWebService.doQuery(`SELECT * FROM Contacts WHERE assigned_user_id=${res?.id} LIMIT 1`)
        const contact = Array.isArray(contactdata) && contactdata[0] ? contactdata[0] : {}; // update this
        localStorage.setItem('locale',  res?.language ? res.language : 'en');
        const contactid: string = contact?.id ?? coreboslogindata?.user?.contactid;
        const authData = { user: {...res, gender: contact?.gender}, contactid: contactid, contact: contact, logindata: coreboslogindata, language:  res?.language}; // update this
        await localDB_setItem('auth', authData);
        return Promise.resolve(authData);      
    } else {
        return Promise.reject(new Error(CoreBosWebService.lastError()));
    }
}

export const authProvider = {
    login: async ({ username, password }:{ username: any, password: any }) => {
        let logindata: any = null;
        logindata = await CoreBosWebService.doLogin(username, password, false).then((logindata: any) => {
            return handleAuthentication(logindata);
        }).catch(() => {
            return null;
        });
        if(!logindata){
            logindata = await CoreBosWebService.doLoginPortal(username, password, 'plaintext', 'Contacts').then((logindata: any) => {
                return handleAuthentication(logindata);
            }).catch(() => {
                return null;
            })
        }

        if(!logindata){
            return Promise.reject(new Error(CoreBosWebService.lastError()));
        }
        return Promise.resolve(logindata); 
    },
    checkError: (error: any) => Promise.resolve(),
    checkAuth: async (params: any) => {
        try {
            const authData: any = await localDB_getItem('auth');
            const coreboslogindata = authData?.logindata ?? null;
		    return coreboslogindata ? Promise.resolve() : Promise.reject(new Error('Authentication failed'));
        } catch (err) {
            Promise.reject(new Error('Error on authenticating'));
        }
    },
    logout: async () => {
        await localDB_removeItem('auth');
        return Promise.resolve();
    },
    getIdentity: async () => {
        let corebosUser: any = await localDB_getItem('auth');
        corebosUser = corebosUser?.user ? corebosUser : null;
		if(corebosUser){
			return Promise.resolve({
				id: corebosUser?.user?.id,
				contactid: corebosUser?.contactid,
				fullName: corebosUser?.contact?.id ? corebosUser?.contact?.firstname +' '+ corebosUser?.contact?.lastname : corebosUser?.user?.first_name +' '+ corebosUser?.user?.last_name,
				user: corebosUser?.user,
			});
		}

		return Promise.reject(new Error('Error on getting application user'));
    },
    getPermissions: async () => {
        const prefixLocalForageKey: string = await getLocalDataPrefix();
        const permissions = await localDB_getItem(`${prefixLocalForageKey}permissions`) ?? [];
		return permissions;
    },
};

export default authProvider;