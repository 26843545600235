import React from "react";
import { Filter, DateInput, TextInput, SelectInput, ReferenceInput, AutocompleteInput, SelectField, SearchInput } from 'react-admin';
import { OnlyMineInput } from "./OnlyMineInput";
import { dateParser, formatSearchObject } from "../utils/utils";
import { FormattedBooleanInput } from "../utils/helper";


export const CBListFilter = (props: any) => {
    return (
        <Filter {...props} style={{ marginBottom: '20px' }}>
            <SearchInput source="q" alwaysOn variant="outlined" /> 
            {props.onlyMine &&
                <OnlyMineInput fieldName={props.onlyMine.fieldName} btnLabel={props.onlyMine.btnLabel} />
            }
            {
                props.fields.map((field: any, i: number) => {
                    if (typeof field === 'object') {
                        switch (field.uitype) {
                            case '5': //date type
                                return (props?.disabledfield && props?.disabledfield === field.name) ? <DateInput key={i} source={field.name} label={field.label} alwaysOn disabled parse={dateParser} style={props.disabledfieldStyle ?? {}} variant="outlined" /> : <DateInput key={i} source={field.name} label={field.label} parse={dateParser} variant="outlined" />;
                            case '50': //date type
                                return (props?.disabledfield && props?.disabledfield === field.name) ? <DateInput key={i} source={field.name} label={field.label} alwaysOn disabled parse={dateParser} style={props.disabledfieldStyle ?? {}} variant="outlined" /> : <DateInput key={i} source={field.name} label={field.label} parse={dateParser} variant="outlined" />;
                            case 53: {// User Relation: Assigned To
                                let userlist = props.describe[props.resource]?.userlist ?? [];
                                return (
                                    <SelectField
                                        key={field.name}
                                        label={field.label}
                                        source={field.name}
                                        choices={userlist}
                                        optionText="username"
                                        optionValue="userid"
                                    />
                                );
                            }
                            case '10': {//Module Relation

                                if (props?.disabledfield && props?.disabledfield === field.name && props.disabledfieldStyle) {
                                    return null;
                                }

                                if (field.type.refersTo.length === 0) {
                                    return <></>
                                }
                                if (!props?.describe?.[field.type.refersTo[0]]) {
                                    return <></>
                                }
                                const refmod = props?.describe[field.type.refersTo[0]] ?? {};
                                let labelFields = refmod.labelFields;


                                if (labelFields && labelFields.indexOf(',') > -1) {
                                    labelFields = labelFields.split(',')[0];
                                }


                                return (props?.disabledfield && props?.disabledfield === field.name) ?
                                    <ReferenceInput
                                        disabled
                                        alwaysOn
                                        style={props.disabledfieldStyle ?? {}}
                                        variant="outlined"
                                        key={field.name}
                                        label={field.label}
                                        source={field.name}
                                        reference={refmod.name}
                                        filterToQuery={(searchText: any) =>
                                            formatSearchObject(labelFields, searchText)
                                        }>
                                        <AutocompleteInput
                                            variant="outlined"
                                            key={'ref' + field.name}
                                            optionText={labelFields}
                                        />
                                    </ReferenceInput> :

                                    <ReferenceInput
                                        variant="outlined"
                                        key={field.name}
                                        label={field

                                            .label}
                                        source={field.name}
                                        reference={refmod.name}
                                        filterToQuery={(searchText: any) =>
                                            formatSearchObject(labelFields, searchText)
                                        }>
                                        <AutocompleteInput
                                            variant="outlined"
                                            key={'ref' + field.name}
                                            optionText={labelFields}
                                        />
                                    </ReferenceInput>
                            }
                            case '15': //Picklist
                                return (props?.disabledfield && props?.disabledfield === field.name) ? <TextInput key={i} source={field.name} label={field.label} alwaysOn disabled style={props.disabledfieldStyle ?? {}} variant="outlined" /> : <SelectInput key={i} source={field.name} label={field.label} choices={field.type.picklistValues} optionText="value" optionValue="label" defaultValue={field.defaultValue} variant="outlined" />;
                            case '56': //Checkbox
                                return (props?.disabledfield && props?.disabledfield === field.name) ? <FormattedBooleanInput key={i} source={field.name} defaultValue={field.defaultValue} label={field.label} alwaysOn disabled style={props.disabledfieldStyle ?? {}} variant="outlined" /> : <FormattedBooleanInput key={i} source={field.name} label={field.label} variant="outlined" />;
                            default:
                                return (props?.disabledfield && props?.disabledfield === field.name) ? <TextInput key={i} source={field.name} label={field.label} alwaysOn disabled style={props.disabledfieldStyle ?? {}} variant="outlined" /> : <TextInput key={i} source={field.name} label={field.label} variant="outlined" />;
                        }
                    } else {
                        return (props?.disabledfield && props?.disabledfield === field) ? <TextInput key={i} source={field} label={field} alwaysOn disabled style={props.disabledfieldStyle ?? {}} variant="outlined" /> : <TextInput key={i} source={field} label={field} variant="outlined" />;
                    }
                })
            }
        </Filter>
    );
}